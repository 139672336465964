import styled from 'styled-components/macro';
import theme from 'styled-theming';
import { ModalContent } from '@components/ModalWindow';
import {
  colors,
  dark,
  breakpoints,
  effects,
  flex,
  fonts,
  light,
  textSizes
} from '@styles/vars';

export const Container = styled.ul`
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  text-align: -webkit-center;
  justify-content: space-between;
  border-top: 1px solid #C8C8C8;
  background-color: ${theme('theme', {
    light: colors.white,
    dark: '#2a2f32'
  })};
  border-radius: 4px 4px 0 0;
  padding: 0px 5px;

  button {
    font-size: ${textSizes['20']};
    color: colors.blue;
    transition: none;
    min-width: 40px;
    min-height: 10px;
    display: flex;
    ${flex.center};

    &[aria-label='Home'] {
      background: #fff;

      border-radius: 8px;
    }

    .active {
      color: black !important;
    }
    .styleLabel {
      color: black !important;
    }
  }
`;

export const MenuModal = styled(ModalContent)`
  padding: 36px 24px;
  ${flex.col};
  gap: 8px;
`;

export const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;

  .info {
    ${flex.col}
    margin: 0 30px 0 0;
    position: relative;

    .position {
      font-family: ${fonts.accent};
      font-size: ${textSizes['10']};
      text-transform: uppercase;
    }

    .userInfo {
      flex-direction: column;
    }
  }

  .trigger {
    display: flex;
    align-items: center;
    color: ${colors.black};

    .userLogo {
      margin-right: 5px;
      border: 1px solid #2a61ff;
      border-radius: 50%;
      font-size: 19px;
      background-color: #5d87ff;
      color: #ffffff;
      vertical-align: middle;
      width: 2.2rem;
      height: 2.2rem;
      text-align: center;
      line-height: 2.2rem;
      font-weight: 600;
    }
  }

  ${breakpoints.laptopL} {
    margin-right: 20px;
  }

  ${breakpoints.desktop} {
    margin-right: 0;
  }
`;

export const Menu = styled.div`
  position: absolute;
  right: 0;
  // margin-top: 30px !important;
  // margin-right: -100px !important;
  padding: 20px;
  gap: 16px;
  border-radius: 8px;
  font-size: ${textSizes['14']};
  ${flex.col}
  box-shadow: ${effects.widgetShadow};
  transition: var(--transition);
  opacity: 0;
  visibility: hidden;
  min-width: 160px;
  width: max-content;
  background-color: ${theme('theme', {
    light: light.widgetBg,
    dark: dark.widgetBg
  })};

  &.visible {
    opacity: 1;
    visibility: visible;
  }

  button {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;

    i {
      transition: color var(--transition);
      font-size: ${textSizes['16']};
    }

    &:hover,
    &:focus {
      i {
        color: ${theme('theme', {
          light: colors.blue,
          dark: '#fff'
        })};
      }
    }
  }
`;

export const footer_label = styled.div`
  display: table; /* fit content */
  margin: auto; /* center div */
`;
