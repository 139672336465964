import { useState } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Btn from '@ui/Btn';
import LabeledFormInput from '@ui/LabeledFormInput';
import { breakpoints, flex } from '@styles/vars';
import clsx from 'clsx';
import { forgotPasswordAPI } from '@services/auth';
import useNotistack from '@hooks/useNotistack';
import { useNavigate } from 'react-router-dom';
import { colors } from '@styles/vars';
import { Link } from 'react-router-dom';
import { fontSize } from '@mui/system';

const StyledForm = styled.form`
  width: 100%;
  padding: 20px;
  display: flex;
  ${flex.col}
  ${breakpoints.laptop} {
    width: 70%;
  }
`;

const InputWrapper = styled.div`
  margin: 10px 0px;
`;

const BtnWrapper = styled.div`
  margin: 0px;
`;

const forgotPasswordSchema = yup.object({
  email: yup.string().email('Email must be valid').required()
});

const LinkWrapper = styled.div`
  text-align: right;
  margin-top:25px;
`;

export default function ForgotPasswordForm() {
  const [isLoading, setIsLoading] = useState(false);
  const { notify } = useNotistack();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, action) => {
      try {
        setIsLoading(true);
        let res = await forgotPasswordAPI(values.email);
        if (res.isOk) {
          notify(res.detail, 'success');
          navigate('/login');
          action.resetForm();
        } else {
          for (const [key, value] of Object.entries(res.error)) {
            notify(`${key}: ${value}`, 'error');
          }
        }
      } finally {
        setIsLoading(false);
      }
    }
  });

  return (
    <StyledForm onSubmit={formik.handleSubmit}>
      <InputWrapper>
        <LabeledFormInput
          title="Email Id"
          placeholder="Email Id"
          name="email"
          sx={{
            '& .MuiInput-input':{
              fontSize: '16px'
            }
          }}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          error={formik.errors.email}
        />
      </InputWrapper>
      <BtnWrapper>
        <Btn
          className={clsx(isLoading && 'disabled')}
          disabled={isLoading || !formik.isValid}
          type="submit"
          text={!isLoading ? 'Send Email' : 'Please wait...'}
          style={{marginTop: '35px',fontSize:'16px'}}
        />
      </BtnWrapper>
      <LinkWrapper style={{display:'flex',justifyContent:'center',color:colors.blue}}>
        <span style={{color:colors.black}}>Already have an account ?</span><Link to="/login">&nbsp;Sign in</Link>
      </LinkWrapper>
    </StyledForm>
  );
}
