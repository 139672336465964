// styled components
import { Content, Wrapper, Widgets } from './style';
// import { QtyBadge } from '@components/UI/Badge/style';
// import { ControlWrapper } from '@components/GlobalSettingsControls/style';

// components
import { Helmet } from 'react-helmet';
// import {
//   ContrastControl,
//   FullscreenControl,
//   LayoutControl,
//   ScaleControl,
//   ThemeControl,
//   DirectionControl
// } from '@components/GlobalSettingsControls';
import Grid from '@layout/Grid';
import { motion } from 'framer-motion';
import Panel from '@layout/Panel';

// utils
import PropTypes from 'prop-types';

// hooks

import useWindowSize from '@hooks/useWindowSize';

// import useWindowSize from '@hooks/useWindowSize';
import useMobileDetect from 'use-mobile-detect-hook';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useRef, useEffect } from 'react';
import useAuth from '@hooks/useAuth';

const Page = ({
  title,
  children,
  hasBadge,
  qty,
  searchMenu,
  SearchText,
  setSearchText,
  SetSearch,
  Search,
  header = null,
  mobileHideTopBar = false,
  currentURL=false,
  paddingLeft=false,
  currentPage
}) => {
  const { width } = useWindowSize();
  const isMobile = width < 768;

  const pageRef = useRef(null);
  const { user } = useAuth();
  const device = useMobileDetect();
  // const { width } = useWindowSize();
  // const isTablet = width > 767.98;

  // get the current location from the router
  const { pathname } = useLocation();
  // detect if the current location is dashboard
  const isDashboard = pathname.includes('home');
  // modify the path to get corresponding layout from the store
  const layoutKey = pathname.replace('/', '');
  const layouts = useSelector((state) => state['layout'].layout);
  const current = layouts[layoutKey];

  useEffect(() => {
    if (pageRef.current) {
      pageRef.current.scrollTop = 0;
    }
  }, [pathname]);

  return (
    <>
      {!mobileHideTopBar ? (
        <>
          {searchMenu ? (
            <Panel
              loggedInUserDetail={user}
              title={title}
              hasBadge={hasBadge}
              qty={qty}
              SetSearch={SetSearch}
              Search={Search}
              searchMenu={searchMenu}
              SearchText={SearchText}
              setSearchText={setSearchText}
              currentURL={currentURL}
              paddingLeft={paddingLeft}
              currentPage={currentPage}
            />
          ) : (
            <Panel
              loggedInUserDetail={user}
              title={title}
              hasBadge={hasBadge}
              qty={qty}
              currentURL={currentURL}
              paddingLeft={paddingLeft}
              currentPage={currentPage}
            />
          )}
        </>
      ) : null}
      <Content ref={pageRef} mobileHideTopBar={mobileHideTopBar}>
        <Helmet>
          <title>{`ForeverOneApp | ${title}`}</title>
        </Helmet>
        <Widgets>
          {current ? (
            <Grid id={layoutKey} layouts={current} desktop={device.isDesktop()}>
              {children}
            </Grid>
          ) : (
            children
          )}
        </Widgets>
      </Content>
    </>
  );
};

Page.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  hasBadge: PropTypes.bool,
  hasTitle: PropTypes.bool,
  qty: PropTypes.number
};

export default Page;
