import React from 'react';
import Typography from '@mui/material/Typography';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { styled } from '@mui/material/styles';
import { Heading1, Image, ListItem, ListItem1, ListItem2, ContentHeading, TopicHeading, MobileImageInMobile } from "./style"


const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function ImageTutorials({ isMobile, expanded, handleChange }) {
    const hostURL = window.location.origin;
    return (
        <>
            {/* HOME PAGE FLOW */}
            <Accordion id="panel1" expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography sx={{ width: '100%', flexShrink: 0, fontWeight: 600, fontSize: isMobile ? '13px' : '14px' }}>Home Page Flow</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ListItem>
                        1. Log in using your credentials and navigate to the home page. Here, you can view multiple tiles.
                    </ListItem>
                    {isMobile ? <MobileImageInMobile src={`${hostURL}/assets/home/mobile/15.png?v=2`} alt="Image" /> : <Image src={`${hostURL}/assets/home/desktop/1.png?v=2`} alt="Image" />}
                    <ListItem>
                        2. Each tile indicates the type of lead and count for the current month.
                    </ListItem>
                    <ListItem>
                        3. You can view the leads of any month by using a date filter by default it is set to the current month.
                    </ListItem>
                    <ListItem>
                        <b>Untriaged: - </b> View the total number of untriaged leads for the current month.
                    </ListItem>
                    <ListItem>
                        <b>Missed Call: - </b> View the total number of missed call leads for the current month.
                    </ListItem>
                    <ListItem>
                        <b>Missed New Patient: -  </b> View the total number of missed new patient leads for the current month.
                    </ListItem>
                    <ListItem>
                        <b>Triaged: - </b> View the total number of triaged leads for the current month.
                    </ListItem>
                    <ListItem>
                        <b>New Patient Line Call Booking: -  </b> View the total number of new patient line call booking leads for the current month.
                    </ListItem>
                    <ListItem>
                        <b>Online Website Booking: - </b> View the total number of online website bookings and Canceled Appointment leads for the current month.
                    </ListItem>
                    <ListItem>
                        <b>Not Relevant Call: - </b> View the total number of non-relevant leads for the current month.
                    </ListItem>
                    <ListItem>
                        <b>All: - </b> View all types of total leads based on the current month.
                    </ListItem>
                </AccordionDetails>
            </Accordion>
            {/* LEAD LIST FLOW */}
            <Accordion id="panel2" expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <Typography sx={{ width: '100%', flexShrink: 0, fontWeight: 600, fontSize: isMobile ? '13px' : '14px' }}>Lead List Flow</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ListItem>
                        1. Log in using your credentials and navigate to the lead page.
                    </ListItem>
                    <ListItem>
                        2. There are two ways to view leads.
                    </ListItem>
                    <ListItem1>
                        a. you can view all the leads by going to the home page and clicking on the particular tiles.
                    </ListItem1>
                    <ListItem1>
                        b. Go to the leads page and apply a filter to display all leads.
                    </ListItem1>

                    <ContentHeading>
                        On the lead list page, you will see two different types of lead sources:
                    </ContentHeading>
                    <ListItem1>
                        1. New Patient Phone Line
                    </ListItem1>
                    <ListItem1>
                        2. Website Booking Tool
                    </ListItem1>

                    <TopicHeading>
                        1. New Patient Phone Line
                    </TopicHeading>
                    <ListItem>
                        In the leads from the new patient phone line, you will see the following details:
                    </ListItem>
                    <ListItem1>
                        <b>Patient name </b> This displays the patient's name along with their category as either a new or existing caller.
                    </ListItem1>
                    <ListItem1>
                        <b>Mobile number </b> Patient's mobile number.
                    </ListItem1>
                    <ListItem1>
                        <b>Source name </b> The name of the source from which the lead originated is visible i.e: (New Patient Phone Line, Website Booking Tool)
                    </ListItem1>
                    <ListItem1>
                        <b>Booking date </b> The date on which the lead is booked.
                    </ListItem1>
                    <ListItem1>
                        <b>Call summary </b> This provides a detailed summary of the entire call.
                    </ListItem1>
                    <ListItem1>
                        <b>Recording </b> The recording of the call is provided, and you can listen to the entire recording by clicking on the recording button.
                    </ListItem1>
                    <ListItem1>
                        <b>Transcription </b> This provides a detailed transcription of the entire call.
                    </ListItem1>
                    <ListItem1>
                        <b>New patient </b> In this, you are given three options, yes, yes(referral), and no by clicking on their radio button, you can change the type of patient and if you select the no option, you can also enter the reason.
                    </ListItem1>
                    <ListItem1>
                        <b>Call status </b> In the call status section, you can change the status of the call to either "Triaged" or "Untriaged". If you change the value of a new patient, the status will automatically change to "Untriaged."
                    </ListItem1>
                    {isMobile ? <MobileImageInMobile src={`${hostURL}/assets/home/mobile/11.png?v=2`} alt="Image" /> : <Image src={`${hostURL}/assets/home/desktop/2.png?v=2`} alt="Image" />}
                    <TopicHeading>
                        2. Website Booking Tool
                    </TopicHeading>
                    <ListItem>
                        In the leads from the website booking tool, you will see the following details:
                    </ListItem>
                    <ListItem1>
                        <b>Patient name </b> This displays the patient's name along with their category as either a new or existing caller.
                    </ListItem1>
                    <ListItem1>
                        <b>Mobile number </b> Patient's mobile number.
                    </ListItem1>
                    <ListItem1>
                        <b>Source name </b> The name of the source from which the lead originated is visible i.e: (New Patient Phone Line, Website Booking Tool).
                    </ListItem1>
                    <ListItem1>
                        <b>Booking date </b> The date on which the lead is booked.
                    </ListItem1>
                    <ListItem1>
                        <b>Callback done </b> In this, you can change the value of the callback.
                    </ListItem1>
                    <ListItem1>
                        <b>New patient </b> In this, you are given three options, yes, yes(referral), and no by clicking on their radio button, you can change the type of patient and if you select the no option, you can also enter the reason.
                    </ListItem1>
                    <ListItem1>
                        <b>Status </b> In the call status section, you can change the status of the call to either "Triaged" or "Untriaged.", if you change the value of a new patient, the status will automatically change to "Untriaged."
                    </ListItem1>
                    {isMobile ? <MobileImageInMobile src={`${hostURL}/assets/home/mobile/12.png?v=2`} alt="Image" /> : <Image src={`${hostURL}/assets/home/desktop/3.png?v=2`} alt="Image" />}

                    <ContentHeading>
                        Multiple filters are provided on the lead page through which you can filter the leads.
                    </ContentHeading>
                    <ListItem1>
                        <b>Search Filter </b> Using the search filter, you can filter leads based on any search string.
                    </ListItem1>
                    <ListItem1>
                        <b>Date Filter </b> In the date filter, you can view the leads of any day or any month by filtering.
                    </ListItem1>
                    <ListItem1>
                        <b>Filter </b> Four types of filters are given in the filter:-
                    </ListItem1>
                    <ListItem2>
                        a.&nbsp; 1.follow up, 2.status, 3.category, 4.source.
                    </ListItem2>
                    <ListItem2>
                        b.&nbsp; By applying these filters we can view the particular type of leads.
                    </ListItem2>
                    {isMobile ? <MobileImageInMobile src={`${hostURL}/assets/home/mobile/14.png?v=2`} alt="Image" /> : <Image src={`${hostURL}/assets/home/desktop/4.png?v=2`} alt="Image" />}

                    <ContentHeading>
                        Update Lead
                    </ContentHeading>
                    <ListItem1>
                        1. Go to the lead page and select the lead to update.
                    </ListItem1>
                    <ListItem1>
                        2. Click on the pencil icon.
                    </ListItem1>
                    <ListItem1>
                        3. Modify the necessary details.
                    </ListItem1>
                    <ListItem1>
                        4. Click "Save."
                    </ListItem1>
                    {isMobile ? <MobileImageInMobile src={`${hostURL}/assets/home/mobile/13.png?v=2`} alt="Image" /> : <Image src={`${hostURL}/assets/home/desktop/5.png?v=2`} alt="Image" />}
                </AccordionDetails>
            </Accordion>
            {/* INSTSTRUCTION IPHONE HOME SCREEN APP */}
            {/* <Accordion id="panel3" expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography sx={{ width: '100%', flexShrink: 0, fontWeight: 600, fontSize: isMobile ? '13px' : '14px' }}>(iPhone) Steps to add ForeverOneApp/Visibolo.com as a mobile app </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ListItem>
            Instructions For iPhone:
          </ListItem>
          <ListItem1>
            1. Open Safari or any other browser.
            {isMobile ? <MobileImageInMobile src={`${hostURL}/assets/home/mobile/1.png?v=2`} alt="Image" /> : <MobileImageInDesktop src={`${hostURL}/assets/home/mobile/1.png?v=2`} alt="Image" />}
          </ListItem1>
          <ListItem1>
            2. Type in visibolo.com.
            {isMobile ? <MobileImageInMobile src={`${hostURL}/assets/home/mobile/2.png?v=2`} alt="Image" /> : <MobileImageInDesktop src={`${hostURL}/assets/home/mobile/2.png?v=2`} alt="Image" />}
          </ListItem1>
          <ListItem1>
            3. Click on the arrow button located at the bottom of the Safari screen (refer to the screenshot).
            {isMobile ? <MobileImageInMobile src={`${hostURL}/assets/home/mobile/3.png?v=2`} alt="Image" /> : <MobileImageInDesktop src={`${hostURL}/assets/home/mobile/3.png?v=2`} alt="Image" />}
          </ListItem1>
          <ListItem1>
            4. Scroll up to locate the option to add to the Home Screen.
            {isMobile ? <MobileImageInMobile src={`${hostURL}/assets/home/mobile/4.png?v=2`} alt="Image" /> : <MobileImageInDesktop src={`${hostURL}/assets/home/mobile/4.png?v=2`} alt="Image" />}
          </ListItem1>
          <ListItem1>
            5. Choose the name "ForeverOneApp" then add.
            {isMobile ? <MobileImageInMobile src={`${hostURL}/assets/home/mobile/5.png?v=2`} alt="Image" /> : <MobileImageInDesktop src={`${hostURL}/assets/home/mobile/5.png?v=2`} alt="Image" />}
          </ListItem1>
          <ListItem1>
            6. Navigate to the home page of your iPhone where all apps are displayed, and find the ForeverOneApp icon.
          </ListItem1>
          <ListItem1>
            7. Alternatively, you can scroll and search for the app named "ForeverOneApp".
          </ListItem1>
        </AccordionDetails>
      </Accordion> */}
            {/* INSTSTRUCTION ANDROID HOME SCREEN APP */}
            {/* <Accordion id="panel4" expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography sx={{ width: '100%', flexShrink: 0, fontWeight: 600, fontSize: isMobile ? '13px' : '14px' }}>(Android phone) Steps to add ForeverOneApp/Visibolo.com as a mobile app  </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ListItem>
            Instructions For Android:
          </ListItem>
          <ListItem1>
            1. Open any browser.
            {isMobile ? <MobileImageInMobile src={`${hostURL}/assets/home/mobile/6.png?v=2`} alt="Image" />
            : <MobileImageInDesktop src={`${hostURL}/assets/home/mobile/6.png?v=2`} alt="Image" />
            }
          </ListItem1>
          <ListItem1>
            2. Type in visibolo.com.
            {isMobile ? <MobileImageInMobile src={`${hostURL}/assets/home/mobile/7.png?v=2`} alt="Image" /> : <MobileImageInDesktop src={`${hostURL}/assets/home/mobile/7.png?v=2`} alt="Image" />}
            
          </ListItem1>
          <ListItem1>
            3. Click on the three dots located at the top of the browser screen (refer to the screenshot).
            {isMobile ? <MobileImageInMobile src={`${hostURL}/assets/home/mobile/8.png?v=2`} alt="Image" /> : <MobileImageInDesktop src={`${hostURL}/assets/home/mobile/8.png?v=2`} alt="Image" />}
            
          </ListItem1>
          <ListItem1>
            4. Scroll down to locate the option to add to the Home Screen.
            {isMobile ? <MobileImageInMobile src={`${hostURL}/assets/home/mobile/9.png?v=2`} alt="Image" /> : <MobileImageInDesktop src={`${hostURL}/assets/home/mobile/9.png?v=2`} alt="Image" />}
          </ListItem1>
          <ListItem1>
            5. Choose the name "ForeverOneApp" then add.
            {isMobile ? <MobileImageInMobile src={`${hostURL}/assets/home/mobile/10.png?v=2`} alt="Image" /> : <MobileImageInDesktop src={`${hostURL}/assets/home/mobile/10.png?v=2`} alt="Image" />}
          </ListItem1>
          <ListItem1>
            6. Navigate to the home page of your phone where all apps are displayed, and find the ForeverOneApp icon.
          </ListItem1>
          <ListItem1>
            7. Alternatively, you can scroll and search for the app named "ForeverOneApp".
          </ListItem1>
        </AccordionDetails>
      </Accordion> */}
            {/* INSTSTRUCTION TO REGISTER A USER */}
            <Accordion id="panel5" expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                    <Typography sx={{ width: '100%', flexShrink: 0, fontWeight: 600, fontSize: isMobile ? '13px' : '14px' }}>User Registration</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ListItem>
                        Instructions For Register A User:
                    </ListItem>
                    <ListItem1>
                        1. Navigate to the users page.
                    </ListItem1>
                    <ListItem1>
                        2. Select the option to add users.
                    </ListItem1>
                    {isMobile ? <MobileImageInMobile src={`${hostURL}/assets/home/mobile/registerUser1.png?v=2`} alt="Image" /> : <Image src={`${hostURL}/assets/home/desktop/registerUser1.png?v=2`} alt="Image" />}
                    <ListItem1>
                        3. Provide all required details.
                    </ListItem1>
                    {isMobile ? <MobileImageInMobile src={`${hostURL}/assets/home/mobile/registerUser2.png?v=2`} alt="Image" /> : <Image src={`${hostURL}/assets/home/desktop/registerUser2.png?v=2`} alt="Image" />}
                    <ListItem1>
                        4. Save the information by clicking the save button.
                    </ListItem1>
                    {isMobile ? <MobileImageInMobile src={`${hostURL}/assets/home/mobile/registerUser3.png?v=2`} alt="Image" /> : <Image src={`${hostURL}/assets/home/desktop/registerUser3.png?v=2`} alt="Image" />}
                    <ListItem1>
                        5. User creation completed successfully.Login credentials have been sent to the registered email.
                    </ListItem1>
                    {isMobile ? <MobileImageInMobile src={`${hostURL}/assets/home/mobile/registerUser4.png?v=2`} alt="Image" /> : <Image src={`${hostURL}/assets/home/desktop/registerUser4.png?v=2`} alt="Image" />}
                    <ListItem1>
                        6. User can log in using the credentials sent to the registered email address.
                    </ListItem1>
                </AccordionDetails>
            </Accordion>
            {/* INSTSTRUCTION TO REGISTER A USER */}
            <Accordion id="panel6" expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                    <Typography sx={{ width: '100%', flexShrink: 0, fontWeight: 600, fontSize: isMobile ? '13px' : '14px' }}>User Login</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ListItem>
                        Instructions for login a user:
                    </ListItem>
                    <ListItem1>
                        {
                            isMobile ?
                                <>1. Navigate to foreveroneapp.</> : <>1. Navigate to foreveroneapp.com</>
                        }

                    </ListItem1>
                    {isMobile ? <MobileImageInMobile src={`${hostURL}/assets/home/mobile/login1.png?v=2`} alt="Image" /> : <Image src={`${hostURL}/assets/home/desktop/login1.png?v=2`} alt="Image" />}
                    <ListItem1>
                        2. Enter your email id and password to log in.
                    </ListItem1>
                    {isMobile ? <MobileImageInMobile src={`${hostURL}/assets/home/mobile/login2.png?v=2`} alt="Image" /> : <Image src={`${hostURL}/assets/home/desktop/login2.png?v=2`} alt="Image" />}
                    <ListItem1>
                        3. Login Successfully:
                    </ListItem1>
                    <ListItem2>
                        a. If this is your first time logging in, you will be prompted to change your password.
                        {isMobile ? <MobileImageInMobile src={`${hostURL}/assets/home/mobile/login3.png?v=2`} alt="Image" /> : <Image src={`${hostURL}/assets/home/desktop/login3.png?v=2`} alt="Image" />}
                    </ListItem2>
                    <ListItem2>
                        b. Enter your new password, then submit. Your password has now been changed.
                    </ListItem2>
                </AccordionDetails>
            </Accordion>
        </>
    )
}

export default ImageTutorials