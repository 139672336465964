// export const menu = [
//   {
//     cat: 'dashboard',
//     icon: 'blocks',
//     links: [
//       { name: 'Dashboard A', link: '/dashboard_a' },
//       { name: 'Dashboard B', link: '/dashboard_b' },
//       { name: 'Dashboard C', link: '/dashboard_c' },
//       { name: 'Dashboard D', link: '/dashboard_d' },
//       { name: 'Dashboard E', link: '/dashboard_e' },
//       { name: 'Dashboard F', link: '/dashboard_f' },
//       { name: 'Dashboard G', link: '/dashboard_g' },
//       { name: 'Dashboard H', link: '/dashboard_h' },
//       { name: 'Dashboard I', link: '/dashboard_i' },
//       { name: 'Dashboard J', link: '/dashboard_j' },
//       { name: 'Dashboard K', link: '/dashboard_k' },
//       { name: 'Page 404', link: '/404' }
//     ]
//   },
//   {
//     cat: 'appointments',
//     icon: 'calendar',
//     links: [
//       { name: 'Patient Appointments', link: '/patient_appointments' },
//       { name: 'Doctor Appointments', link: '/doctor_appointments' }
//     ]
//   },
//   {
//     cat: 'patients',
//     icon: 'users',
//     links: [
//       { name: 'Patients', link: '/patients' },
//       { name: 'Tests', link: '/tests' }
//     ]
//   },
//   {
//     cat: 'doctors',
//     icon: 'stethoscope',
//     links: [
//       { name: 'Doctors', link: '/doctors' },
//       { name: 'Staff', link: '/staff' }
//     ]
//   },
//   {
//     cat: 'messages',
//     icon: 'comment',
//     links: [
//       { name: 'Doctor Messenger', link: '/doctor_messenger' },
//       { name: 'Patient Messenger', link: '/patient_messenger' }
//     ]
//   },
//   {
//     cat: 'reviews',
//     icon: 'star',
//     links: [
//       { name: 'Doctor Reviews', link: '/doctor_reviews' },
//       { name: 'Patient Reviews', link: '/patient_reviews' }
//     ]
//   },
//   {
//     icon: 'wallet',
//     name: 'Finances',
//     link: '/finances'
//   },
//   {
//     icon: 'settings',
//     name: 'Settings',
//     link: '/settings'
//   }
// ];

import { lazy } from 'react';

import { TiHome } from 'react-icons/ti';
import { IoHomeSharp } from 'react-icons/io5';
import { MdTune, MdOutlineMessage, MdMessage } from 'react-icons/md';
import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import { FaUsers } from 'react-icons/fa6';
import { PiUsersThreeBold } from 'react-icons/pi';

const HomeOutlinedIcon = lazy(() => import('@mui/icons-material/HomeOutlined'));
const AddOutlined = lazy(() => import('@mui/icons-material/AddOutlined'));
const GroupOutlined = lazy(() => import('@mui/icons-material/GroupOutlined'));
const TuneOutlined = lazy(() => import('@mui/icons-material/TuneOutlined'));
const MessageOutlined = lazy(() => import('@mui/icons-material/MessageOutlined'));

const DashBoard = lazy(() => import('@pages/DashBoard'));
const Settings = lazy(() => import('@pages/Settings'));
const ClinicDetails = lazy(() => import('@pages/ClinicDetails'));
const Profile = lazy(() => import('@pages/Profile'));
const Privacy = lazy(() => import('@pages/Privacy'));
const ClinicList = lazy(() => import('@pages/ClinicList'));
const AddClinic = lazy(() => import('@pages/AddClinic'));
const EditClinic = lazy(() => import('@pages/EditClinic'));
const AddUser = lazy(() => import('@pages/AddUser'));
const UserList = lazy(() => import('@pages/UserList'));
const UserDetails = lazy(() => import('@pages/UserDetails'));
const EditUser = lazy(() => import('@pages/EditUser'));
const IntegrationKeyList = lazy(() => import('@pages/IntegrationKeyList'));
const IntegrationKeyDetail = lazy(() => import('@pages/IntegrationKeyDetail'));
const EditIntegrationKey = lazy(() => import('@pages/EditIntegrationKey'));
const LeadList = lazy(() => import('@pages/LeadList'));
const ChangePassword = lazy(() => import('@pages/ChangePassword'));
const ChangeEmail = lazy(() => import('@pages/ChangeEmail'));
const ClinicDetailsForAdmin = lazy(() =>
  import('@pages/ClinicDetailsForAdmin')
);
const EditClinicForAdmin = lazy(() => import('@pages/EditClinicForAdmin'));
const AddMessageTemplate = lazy(() => import('@pages/AddMessageTemplate'))
const MsgTemplateList = lazy(() => import('@pages/MessageTemplateList'))
const EditMessageTemplate = lazy(() => import('@pages/EditMsgTemplate'))
const IntegrationKeyDetailForAdmin = lazy(() =>
  import('@pages/IntegrationKeyDetailForAdmin')
);
const EditIntegrationKeyforAdmin = lazy(() =>
  import('@pages/EditIntegrationKeyForAdmin')
);
const EditLeadDetail = lazy(() => import('@pages/EditLeadDetail'));
// const NotificationList = lazy(() => import('@pages/NotificationList'));
const Faq = lazy(() => import('@pages/Faq'));
const WebsiteTutorial = lazy(() => import('@pages/WebsiteTutorial'));
const AddPhrases = lazy(() => import('@pages/AddPhrases'));


export const roles = {
  SUPER_ADMIN: 'super admin',
  CLINIC_ADMIN: 'admin'
};

export const menu = [
  {
    icon: <HomeOutlinedIcon />,
    name: 'Home',
    link: '/home',
    role: [roles.CLINIC_ADMIN, roles.SUPER_ADMIN],
    isVisibleInSidebar: [roles.CLINIC_ADMIN, roles.SUPER_ADMIN],
    component: <DashBoard />
  },
  {
    name: 'Leads',
    icon: <TuneOutlined />,
    link: '/leads',
    role: [roles.CLINIC_ADMIN, roles.SUPER_ADMIN],
    isVisibleInSidebar: [roles.CLINIC_ADMIN, roles.SUPER_ADMIN],
    component: <LeadList />
  },
  {
    name: 'Clinics',
    icon: <AddOutlined />,
    link: '/clinics',
    role: [roles.SUPER_ADMIN],
    isVisibleInSidebar: [roles.CLINIC_ADMIN, roles.SUPER_ADMIN],
    component: <ClinicList />,
    links: []
  },
  {
    name: 'Users',
    icon: <GroupOutlined />,
    link: '/users',
    role: [roles.CLINIC_ADMIN, roles.SUPER_ADMIN],
    isVisibleInSidebar: [roles.CLINIC_ADMIN, roles.SUPER_ADMIN],
    component: <UserList />,
    links: []
  },
  {
    name: 'API Integrations',
    icon: 'gear',
    link: '/integration',
    role: [roles.SUPER_ADMIN],
    isVisibleInSidebar: [],
    component: <IntegrationKeyList />,
    links: []
  },

  {
    icon: 'plus',
    name: 'Clinic Detail',
    link: '/clinics/:id',
    role: [roles.SUPER_ADMIN],
    isVisibleInSidebar: [],
    component: <ClinicDetails />
  },
  {
    icon: 'plus',
    name: 'Clinic Detail',
    link: '/clinics/edit',
    role: [roles.CLINIC_ADMIN],
    isVisibleInSidebar: [],
    component: <EditClinicForAdmin />
  },
  {
    name: 'Message Templates',
    icon: <MessageOutlined />,
    link: '/templates',
    role: [roles.CLINIC_ADMIN],
    isVisibleInSidebar: [roles.CLINIC_ADMIN],
    component: <MsgTemplateList />
  },
  {
    icon: 'comment-text',
    name: 'Edit Template',
    link: '/templates/edit/:id',
    role: [roles.CLINIC_ADMIN],
    isVisibleInSidebar: [],
    component: <EditMessageTemplate />
  },
  {
    icon: 'comment-text',
    name: 'Add Template',
    link: '/templates/add',
    role: [roles.CLINIC_ADMIN],
    isVisibleInSidebar: [],
    component: <AddMessageTemplate />
  },
  // {
  //   name: 'API Integrations',
  //   icon: 'gear',
  //   link: '/integration/edit',
  //   role: [roles.CLINIC_ADMIN],
  //   isVisibleInSidebar: [],
  //   component: <EditIntegrationKeyforAdmin />,
  //   links: []
  // },
  // {
  //   name: 'Notifications',
  //   icon: 'bell',
  //   link: '/notification',
  //   role: [roles.CLINIC_ADMIN, roles.SUPER_ADMIN],
  //   isVisibleInSidebar: [],
  //   component: <NotificationList />,
  //   links: []
  // },
  {
    icon: 'clinic',
    name: 'Edit Clinic',
    link: '/clinics/edit',
    role: [roles.CLINIC_ADMIN],
    isVisibleInSidebar: [],
    component: <EditClinicForAdmin />
  },
  {
    icon: 'profile',
    name: 'Profile',
    link: '/profile',
    role: [roles.CLINIC_ADMIN, roles.SUPER_ADMIN],
    isVisibleInSidebar: [],
    component: <Profile />
  },
  {
    icon: 'privacy',
    name: 'Privacy',
    link: '/privacy',
    role: [roles.CLINIC_ADMIN, roles.SUPER_ADMIN],
    isVisibleInSidebar: [],
    component: <Privacy />
  },
  {
    icon: 'clinic',
    name: 'Add Clinic',
    link: '/clinics/add',
    role: [roles.SUPER_ADMIN],
    isVisibleInSidebar: [],
    component: <AddClinic />
  },
  {
    icon: 'clinic',
    name: 'Edit Clinic',
    link: '/clinics/:id/edit',
    role: [roles.SUPER_ADMIN],
    isVisibleInSidebar: [],
    component: <EditClinic />
  },
  {
    icon: 'user',
    name: 'Add user',
    link: '/users/add/',
    role: [roles.CLINIC_ADMIN, roles.SUPER_ADMIN],
    isVisibleInSidebar: [],
    component: <AddUser />
  },
  {
    icon: 'user',
    name: 'User Detail',
    link: '/users/:id',
    role: [roles.CLINIC_ADMIN, roles.SUPER_ADMIN],
    isVisibleInSidebar: [],
    component: <UserDetails />
  },
  {
    icon: 'user',
    name: 'Edit User',
    link: '/users/:id/edit',
    role: [roles.CLINIC_ADMIN, roles.SUPER_ADMIN],
    isVisibleInSidebar: [],
    component: <EditUser />
  },
  {
    icon: 'settings',
    name: 'Integration Detail',
    link: '/integration/:id',
    role: [roles.SUPER_ADMIN],
    isVisibleInSidebar: [],
    component: <IntegrationKeyDetail />
  },
  {
    icon: 'settings',
    name: 'Edit Integration',
    link: '/integration/:id/edit',
    role: [roles.SUPER_ADMIN],
    isVisibleInSidebar: [],
    component: <EditIntegrationKey />
  },
  // {
  //   icon: 'gear',
  //   name: 'API Integration',
  //   link: '/integration/edit',
  //   role: [roles.CLINIC_ADMIN],
  //   isVisibleInSidebar: [roles.CLINIC_ADMIN],
  //   component: <EditIntegrationKeyforAdmin />
  // },
  // {
  //   icon: 'gear',
  //   name: 'API Integration',
  //   link: '/integration/edit',
  //   role: [roles.CLINIC_ADMIN],
  //   isVisibleInSidebar: [],
  //   component: <EditIntegrationKeyforAdmin />
  // },
  {
    icon: 'user',
    name: 'Change Email',
    link: '/change-email',
    role: ['admin', 'super admin'],
    isVisibleInSidebar: [],
    component: <ChangeEmail />
  },
  {
    icon: 'user',
    name: 'Change Password',
    link: '/change-password/',
    role: ['admin', 'super admin'],
    isVisibleInSidebar: [],
    component: <ChangePassword />
  },
  {
    icon: 'lead',
    name: 'Lead Detail',
    link: '/leads/:source/:id/edit',
    role: ['admin', 'super admin'],
    isVisibleInSidebar: [],
    component: <EditLeadDetail />
  },
  {
    name: 'FAQ',
    icon: 'faq',
    link: '/faq',
    role: [roles.CLINIC_ADMIN, roles.SUPER_ADMIN],
    isVisibleInSidebar: [],
    links: [],
    component: <Faq/>,
  },
  {
    name: 'Tutorial',
    icon: 'appTutorial',
    link: '/app-tutorial',
    role: [roles.CLINIC_ADMIN, roles.SUPER_ADMIN],
    isVisibleInSidebar: [],
    links: [],
    component: <WebsiteTutorial/>,
  },
  {
    name: 'Phrases',
    icon: 'addPhrases',
    link: '/add-phrases',
    role: [roles.SUPER_ADMIN],
    isVisibleInSidebar: [],
    links: [],
    component: <AddPhrases/>,
  },
];

export const BottomMenuButtons = [
  {
    name: 'Home',
    icon: <TiHome/>,
    selectedIcon: <IoHomeSharp/>,
    link: '/home',
    role: [roles.CLINIC_ADMIN, roles.SUPER_ADMIN],

  },
  {
    name: 'Leads',
    icon: <MdTune/>,
    selectedIcon: <MdTune/>,
    link: '/leads',
    role: [roles.CLINIC_ADMIN, roles.SUPER_ADMIN],

  },
  {
    name: 'Clinics',
    icon: <LocalHospitalOutlinedIcon/>,
    selectedIcon: <LocalHospitalIcon/>,
    link: '/clinics',
    role: [roles.SUPER_ADMIN],

  },
  {
    name: 'Templates',
    icon: <MdOutlineMessage/>,
    selectedIcon: <MdMessage/>,
    link: '/templates',
    role: [roles.CLINIC_ADMIN],

  },
  {
    name: 'Users',
    icon: <PiUsersThreeBold/>,
    selectedIcon: <FaUsers/>,
    link: '/users',
    role: [roles.CLINIC_ADMIN, roles.SUPER_ADMIN],
  },
]