import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { colors } from '@styles/vars';
import { ArrowBack, ArrowLeft } from '@mui/icons-material';

const NavigationWrapper = styled.div`
  display: flex;
  z-index: 1;
`;
const BackButton = styled.div`
  cursor: pointer;
  margin: 10px 5px 10px 5px;
`;

const BreadCrumb = (props) => {
  const location = useLocation();
  const length = props.match.length - 1;
  
  const handleGoBack = () => {
    window.history.back();
  };
  return (
    <>
      {!props.isMobile ? (
        <NavigationWrapper>
          {props.match.map((data, index) => (
            <>
              <Link
                key={data.pathname + '_' + index}
                to={data.pathname}
                style={
                  data.pathname === location.pathname
                    ? {
                        color: colors.black,
                        paddingRight: '5px',
                        pointerEvents: 'none',
                        marginBottom: '15px'
                      }
                    : {
                        color: colors.primary,
                        paddingRight: '5px',
                        marginBottom: '15px'
                      }
                }
              >
                {data.title}
              </Link>
              {length !== index ? <KeyboardArrowRightIcon /> : null}
            </>
          ))}
        </NavigationWrapper>
      ) : 
          <BackButton onClick={handleGoBack}>
            <ArrowBack/>
          </BackButton>   
      }
    </>
  );
};

export default BreadCrumb;
