import Navbar from '@layout/Navbar/index.jsx';
import Footer from '@layout/Footer';
import BreadCrumb from '@widgets/BreadCrumbs';
import PrivacyPolicyDetail from '@widgets/PrivacyPolicyDetail';
import useWindowSize from '@hooks/useWindowSize';

function PrivacyPolicyPublic() {
    const { width } = useWindowSize();
    const isMobile = width < 768;
    return (
        <>
            <Navbar />
            <div style={{ padding: '0 16px' }}>
                <BreadCrumb
                    isMobile={true}
                    match={[
                        { pathname: '/home', title: 'Home' },
                        { pathname: `/privacy-policy`, title: 'Privacy Policy' }
                    ]}
                />
                <PrivacyPolicyDetail />
            </div>
            <Footer isMobile={isMobile} marginTop={isMobile ? '3%' : '1%'} />
        </>
    )
}

export default PrivacyPolicyPublic