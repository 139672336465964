export const colors = {
  black: '#000000',
  pink: '#DE0D92',
  yellow: '#FDCA40',
  peach: '#FF715B',
  teal: '#34D1BF',
  green: '#4DABAB',
  red: '#ED0423',
  absolute_red: '#FF0000',
  purple: '#6665DD',
  orange: '#F17105',
  azure: '#0496FF',
  blue: '#5D87FF',
  electro: '#29E7CD',
  gray: '#636D73',
  gray1 : '#808080',
  smokedGray: '#ccc',
  secondary: '#90A1AC',
  light_gray: '#E4EAF0',
  dark: '#25292D',
  success: '#59CE72',
  error: '#d32f2f',
  primary: '#1976d2',
  white: '#FFFFFF',
  lightSkyBlue: '#1976d20f',
  bleachWhite: '#cccccc63',
  lightGrey : '#f5f5f5',
  agent: '#006dff',
  speaker: '#35ae7f',
  aliceblue: '#f0f8ff',
  orangepeel:'#FFA10D',
  outline:'#c4c4c4',
  footerBackground:'#626264',
  transparentColor:'#00000091',
  skyBlue: '#d5e0ff'
};

export const confirmedPalette = {
  cold: colors.peach,
  fracture: colors.green,
  concussion: colors.azure,
  hepatitis: colors.purple,
  dermatitis: colors.electro,
  diabetes: colors.pink
};

export const fonts = {
  accent: '"Rubik", sans-serif',
  body: '"Poppins", sans-serif'
};

export const textSizes = {
  8: '0.5rem',
  10: '0.625rem',
  12: '0.75rem',
  14: '0.875rem',
  16: '1rem',
  18: '1.125rem',
  20: '1.25rem',
  24: '1.5rem',
  26: '1.625rem',
  28: '1.75rem',
  32: '2rem',
  40: '2.5rem'
};

export const light = {
  bodyBg: '#F1F5F8',
  widgetBg: '#fff',
  highlight: '#FAFBFD',
  text: '#414D55',
  shadow: 'linear-gradient(270deg, rgba(255, 255, 255, 0.0001) 0%, #fff 100%)'
};

export const dark = {
  bodyBg: '#090A0A',
  widgetBg: '#171819',
  highlight: '#131414',
  text: '#9EA7AC',
  shadow: 'linear-gradient(270deg, rgba(23, 24, 25, 0.0001) 0%, #171819 100%)',
  shadowDarker:
    'linear-gradient(270deg, rgba(23, 24, 25, 0.0001) 0%, #090A0A 100%)'
};

export const effects = {
  dashedDark: `1px dashed ${colors.dark}`,
  dashedLight: `1px dashed #A2C0D4`,
  widgetShadow: '0 1px 8px rgba(20, 46, 110, 0.1)',
  darkerShadow: '0 1px 8px rgba(0, 0, 0, 0.3)'
};

export const dimensions = {
  mobileS: '320px',
  landscapeS: '567.98px',
  mobileM: '374.98px',
  mobileL: '413.98px',
  tablet: '767.98px',
  laptop: '1023.98px',
  laptopL: '1279.98px',
  desktop: '1599.98px'
};

export const breakpoints = {
  mobileS: `@media screen and (min-width: ${dimensions.mobileS})`,
  landscapeS: `@media screen and (min-width: ${dimensions.landscapeS})`,
  mobileM: `@media screen and (min-width: ${dimensions.mobileM})`,
  mobileL: `@media screen and (min-width: ${dimensions.mobileL})`,
  tablet: `@media screen and (min-width: ${dimensions.tablet})`,
  laptop: `@media screen and (min-width: ${dimensions.laptop})`,
  laptopL: `@media screen and (min-width: ${dimensions.laptopL})`,
  desktop: `@media screen and (min-width: ${dimensions.desktop})`
};

export const flex = {
  col: `
      display: flex;
      flex-direction: column;
    `,
  center: `
     align-items: center;
     justify-content: center;
    `,
  between: `
     align-items: center;
     justify-content: space-between;
    `,
  end: `
      align-items: center;
      justify-content: flex-end;
    `
};
