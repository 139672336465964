import { useState } from "react";
import useWindowSize from "@hooks/useWindowSize";
import WidgetsLoader from '@components/WidgetsLoader';
import useAuth from "@hooks/useAuth";
import Footer from "@layout/Footer";
import ContactUsDetailPublic from "@widgets/ContactUsDetail/ContactUsDetailPublic";

function ContactUsPublic() {
    const { user } = useAuth();
    const { width } = useWindowSize();
    const isMobile = width < 768;
    const [isLoading, setIsLoading] = useState(false);
    return (
        <>
            {isLoading ?
                <WidgetsLoader />
                :
                <>
                    <ContactUsDetailPublic isMobile={isMobile} user={user} isLoading={isLoading} setIsLoading={setIsLoading} />
                </>}
            <Footer isMobile={isMobile} marginTop={isMobile ? '3%' : '1%'} />
        </>
    )
}

export default ContactUsPublic