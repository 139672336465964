import styled from "styled-components";
import { colors } from "@styles/vars";

export const ContentWrapper = styled.div`
    // margin: ${(props) => props.isMobile ? '10px' : '15px'};
    padding: ${(props) => props.isMobile ? '15px' : '34px'};
    border-radius: 15px;
    line-height: 24px;
    font-size: ${(props) => props.isMobile ? '14px' : '15px'};
    font-family: 'Open Sans', sans-serif;
    background: ${colors.white};
`
export const Heading = styled.h1`
    margin:0px;
    padding:0px;
    font-size: 24px;
`
export const Paragraph = styled.p`
    margin:0px;
    padding:0px;
`
export const PrivacyPolicyContainer = styled.div`
    margin-left: 17px;
`
export const Paragraph2 = styled.p`
    margin: 0px;
`
export const OrderedList = styled.ol`
    list-style: auto !important;
`
export const List = styled.li`
    margin-top: 10px;
    font-weight: 600;
`
export const DiscList = styled.li`
    margin: 0px;
`

export const UnorderedDiscList = styled.ul`
    list-style: disc !important;
    margin-left: 16px !important;
`

export const Anchor = styled.a`
    color: ${colors.blue};
    text-decoration: underline;
    cursor: pointer;
`