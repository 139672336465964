
import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { handleKeys } from '@utils/common';
import { BtnGrid } from "./style";
import CancelButtonWidget from '@widgets/CancelButtonWidget';
import SubmitButtonWidget from '@widgets/SubmitButtonWidget';
import { sendContactUsMailApi } from '@services/auth';
import useNotistack from '@hooks/useNotistack';
import { colors } from '@styles/vars';

function Form({ isMobile, setIsLoading, isLoading }) {
    const { notify } = useNotistack();
    const initialValues = {
        full_name: '',
        subject: '',
        email: '',
        phone_no: '',
        message: '',
        agree: false
    }

    const ContactUsFormValidation = yup.object({
        full_name: yup.string().required(),
        email: yup.string().email('Email must be valid').required(),
        message: yup.string().required(),
        agree: yup.bool().required().oneOf([true], 'Please accept terms and conditions')
    });

    const formik = useFormik({
        initialValues,
        validateOnChange: true,
        validationSchema: ContactUsFormValidation,
        onSubmit: async (values, { resetForm }) => {
            setIsLoading(true);
            const response = await sendContactUsMailApi(values);
            if (response.status === 200) {
                notify("Your message has been sent successfully.", 'success');
                resetForm();
            } else {
                for (const [key, value] of Object.entries(response.error)) {
                    notify(`${key}: ${value[0]}`, 'error');
                }
            }
            setIsLoading(false);
        }
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}  >
                <Grid item xs={12} sm={12} >
                    <TextField
                        error={formik.touched.full_name && formik.errors.full_name ? true : false}
                        id="full_name"
                        name="full_name"
                        title="Full Name"
                        placeholder="Full Name"
                        label="Full Name *"
                        value={formik.values.full_name}
                        onChange={formik.handleChange}
                        size="small"
                        sx={{ width: '100%' }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        error={formik.touched.email && formik.errors.email ? true : false}
                        id="email"
                        name="email"
                        title="Email"
                        placeholder="Email"
                        label="Email *"
                        size="small"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        sx={{ width: '100%' }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        error={formik.touched.phone_no && formik.errors.phone_no ? true : false}
                        id="phone_no"
                        name="phone_no"
                        title="Phone Number"
                        placeholder="Phone Number"
                        label="Phone Number"
                        size="small"
                        onKeyPress={handleKeys}
                        onChange={formik.handleChange}
                        value={formik.values.phone_no}
                        sx={{ width: '100%' }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        error={formik.touched.subject && formik.errors.subject ? true : false}
                        id="subject"
                        name="subject"
                        title="Subject"
                        placeholder="Phone Number"
                        label="Subject"
                        size="small"
                        onChange={formik.handleChange}
                        value={formik.values.subject}
                        sx={{ width: '100%' }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        error={formik.touched.message && formik.errors.message ? true : false}
                        id="message"
                        label="Messge *"
                        multiline
                        rows={5}
                        onChange={formik.handleChange}
                        value={formik.values.message}
                        sx={{ width: '100%' }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} >
                    <label htmlFor="agree" style={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox
                            name='agree'
                            id='agree'
                            size="medium"
                            style={{ paddingLeft: '0px' }}
                            onChange={formik.handleChange}
                            value={formik.values.agree}
                            sx={{
                                paddingLeft: '0px',
                                color: formik.touched.agree && !formik.values.agree ? colors.red : colors.outline,
                            }} />
                        I agree to the &nbsp;<Link to={'/privacy-policy'} style={{ color: '#0a5dc2', textDecoration: 'underline' }}>Privacy Policy</Link>.
                    </label>
                </Grid>

                {!isMobile ? (
                    <>
                        <BtnGrid item xs={12} sm={12} style={{ display: 'flex', gap: '5px', justifyContent: 'end' }}>
                            <CancelButtonWidget width={'130px'} />
                            <SubmitButtonWidget text='Submit' width={'130px'} />
                        </BtnGrid>
                    </>
                ) : (
                    <BtnGrid item xs={12} sm={12}>
                        <SubmitButtonWidget text='Submit' />
                    </BtnGrid>
                )}
            </Grid>
        </form>
    )
}

export default Form