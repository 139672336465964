import styled from 'styled-components/macro';
import LabeledFormInput from '@components/UI/LabeledFormInput';
import { Grid } from '@mui/material';
import { colors } from '@styles/vars';

export const BtnGrid = styled(Grid)`
  && {
    margin: 0px;
    width: 100%;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const ContactGrid = styled(Grid)`
  && {
    margin: 0px;
    width: 100%;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;



export const LabeledUserInput = styled(LabeledFormInput)`
  width: 100%;
  ${(props) =>
    props.disabled &&
    `
    opacity: 0.5;
    cursor: not-allowed;
    `}
`;

export const GridItem = styled(Grid)`
&&{
  padding: ${(props)=> props.isMobile ? '0px' : '0px 25px !important'};
}`

export const FormContainer = styled.div`
  padding: 10px 26px 30px 26px;
  height: fit-content;
  width: ${(props)=> props.isMobile ? '100%' : '70%'};
  background-color: ${colors.white};
  border: 1px solid #CFE5F7;
  border-radius: 15px;
  top: ${(props)=> props.isMobile ? '0px' : '4%'};
  position: relative;
`
export const ContactUsContainer = styled.div`
  width: 100%;
`

export const ContactUsHeading = styled.div`
  margin-left:16px;
  padding-bottom:20px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-family: sans-serif;
`
export const ContactUsHeadingPublic = styled.div`
    font-size: ${(props)=> props.isMobile ? '20px' : '24px'};
    display: flex;
    justify-content: center;
    font-weight: 600;
    font-family: sans-serif;
    padding-bottom: 7px;
    margin-left: ${(props)=> props.isMobile ? '22%' : '35%'};
`
export const FormContainerPublic = styled.div`
  margin: 0px;
  padding: 25px;
  height: fit-content;
  width: ${(props)=> props.isMobile ? '100%' : '100%'};
  background-color: ${colors.white};
  border: 1px solid #CFE5F7;
  border-radius: 15px;
`
export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`