import Btn from '@components/UI/Btn';
import { colors } from '@styles/vars';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const CancelButtonWidget = ({width, text = 'Cancel', ...props }) => {
  const navigate = useNavigate();

  return (
    <Btn
      border={colors.smokedGray}
      textcolor={colors.gray}
      text={text}
      type="button"
      color={colors.white}
      handler={() => {
        navigate(-1);
      }}
      style={{
        width: width ? width : null
      }}
      {...props}
    />
  );
};

export default CancelButtonWidget;
