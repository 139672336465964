import { useState, useContext } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Btn from '@ui/Btn';
import LabeledFormInput from '@ui/LabeledFormInput';
import { flex } from '@styles/vars';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import { resetPasswordAPI } from '@services/auth';
import useNotistack from '@hooks/useNotistack';
import { useNavigate } from 'react-router-dom';
import { editChangePasswordApi } from '@services/auth';
import { AuthContext } from '@contexts/authContext';
import { breakpoints } from '@styles/vars';

const StyledForm = styled.form`
  width: 100%;
  padding: 20px;
  display: flex;
  ${flex.col}
  ${breakpoints.laptop} {
    width: 70%;
  }
`;

const InputWrapper = styled.div`
  margin: 10px 0px;
`;

const BtnWrapper = styled.div`
  margin: 10px 0px;
`;

const resetPasswordSchema = yup.object({
  password: yup.string().required('This field is required'),
  confirm_password: yup
    .string()
    .required('This field is required')
    .oneOf([yup.ref('password'), null], 'Password did not match')
});

export default function ResetPasswordForm(props) {
  const [isLoading, setIsLoading] = useState(false);
  const { uid, token } = useParams();
  const { notify } = useNotistack();
  const navigate = useNavigate();
  const { setIsAuthenticated } = useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      password: '',
      confirm_password: ''
    },
    validationSchema: resetPasswordSchema,
    onSubmit: async (values, action) => {
      try {
        const keys = ['new_password'];
        const cleanedValues = keys.reduce((acc, key) => {
          acc[key] = values['password'];
          return acc;
        }, {});
        let res;
        if (props.add_new_password) {
          res = await editChangePasswordApi(cleanedValues);
          if (res.isOk) {
            setIsAuthenticated(true);
          }
        } else {
          res = await resetPasswordAPI(values.password, uid, token);
        }
        if (res.isOk) {
          notify('Password changed successfully', 'success');
          action.resetForm();
          navigate('/home');
          // if (props.add_new_password === 'true') {
          //   navigate('/clinics');
          // } else {
          //   navigate('/login');
          // }
        } else {
          for (const [key, value] of Object.entries(res.error)) {
            notify(`${key}: ${value}`, 'error');
          }
        }
      } finally {
        setIsLoading(false);
      }
    }
  });

  return (
    <StyledForm onSubmit={formik.handleSubmit}>
      <InputWrapper>
        <LabeledFormInput
          title="Password"
          placeholder="Password"
          type="password"
          name="password"
          onChange={formik.handleChange}
          value={formik.values.password}
          error={formik.errors.password}
          style={{marginTop:'-25px'}}
        />
      </InputWrapper>
      <InputWrapper>
        <LabeledFormInput
          title="Confirm Password"
          placeholder="Confirm Password"
          type="password"
          name="confirm_password"
          onChange={formik.handleChange}
          value={formik.values.confirm_password}
          error={formik.errors.confirm_password}
        />
      </InputWrapper>
      <BtnWrapper>
        <Btn
          className={clsx(isLoading && 'disabled')}
          disabled={isLoading || !formik.isValid}
          type="submit"
          text={!isLoading ? 'Submit' : 'Please wait...'}
          style={{marginTop : '18px'}}
        />
      </BtnWrapper>
    </StyledForm>
  );
}
