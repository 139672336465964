import styled from 'styled-components';
import ForgotPasswordForm from './Form';
import { breakpoints, colors } from '@styles/vars';

const Container = styled.div`
  maring: 0px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 25px 2px;
  z-index:1;

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 40%;
  border-radius: 25px;
  background: ${colors.white};

  ${breakpoints.laptop} {
    width: 40%;
  }
`;

const MobileContainer = styled.div`
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius:10px;
  width: 95%;
  padding: 30px 0px;
  background:${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index:1;
`

const StyledHeader = styled.h1`
  color: ${colors.dark};
`;

export default function ForgotPasswordFormContainer(props) {
  return (
    <>
      {props.isMobile ?

        <MobileContainer>
          <StyledHeader>Forgot Password</StyledHeader>
          <ForgotPasswordForm />
        </MobileContainer>
        : <Container>
          <StyledHeader>Forgot Password</StyledHeader>
          <ForgotPasswordForm />
        </Container>
      }
    </>

  )
}