import { formatApiResponse } from '@utils/helpers';
import { api, protectedApi } from '@api';
import { getRefreshToken } from '@contexts/authContext';

export const ACCESS_TOKEN_KEY = 'accessToken';
export const REFRESH_TOKEN_KEY = 'refreshToken';

export const loginApi = async (email, password, keep_logged_in) => {
  try {
    const res = await api.post('/login', { email, password, keep_logged_in });
    return formatApiResponse(res);
  } catch (e) {
    return formatApiResponse(e);
  }
};

export const logoutApi = async () => {
  const refreshToken = {
    refresh: getRefreshToken()
  };
  try {
    const res = await protectedApi.post('/logout', refreshToken);
    // // Clear the user's session or token from the client-side storage.
    if (res.status === 204) {
      window.localStorage.removeItem(ACCESS_TOKEN_KEY);
      window.localStorage.removeItem(REFRESH_TOKEN_KEY);
    }
    return formatApiResponse(res);
  } catch (e) {
    return formatApiResponse(e);
  }
};

export const forgotPasswordAPI = async (email) => {
  const endpoint = `/forgot-password`;
  try {
    const res = await api.post(endpoint, { email });
    return formatApiResponse(res);
  } catch (e) {
    return formatApiResponse(e);
  }
};

export const getloggedInUserDetailApi = async () => {
  try {
    const res = await protectedApi.get('/users/profile', {});
    return formatApiResponse(res);
  } catch (e) {
    return formatApiResponse(e);
  }
};

export const editloggedInUserDetailApi = async (data) => {
  const endPoint = '/users/profile';
  try {
    const res = await protectedApi.put(endPoint, data);
    return formatApiResponse(res);
  } catch (e) {
    return formatApiResponse(e);
  }
};

export const editChangePasswordApi = async (data) => {
  const endpoint = `/change-password`;
  try {
    const res = await protectedApi.put(endpoint, data);
    return formatApiResponse(res);
  } catch (e) {
    return formatApiResponse(e);
  }
};

export const resetPasswordAPI = async (password, uidb64, token) => {
  const endpoint = '/set-password/';
  try {
    const res = await api.patch(endpoint, { password, uidb64, token });
    return formatApiResponse(res);
  } catch (e) {
    return formatApiResponse(e);
  }
};

export const verifyPasswordApi = async (data) => {
  const endpoint = `/verify-password/`;
  try {
    const res = await protectedApi.post(endpoint, data);
    return formatApiResponse(res);
  } catch (e) {
    return formatApiResponse(e);
  }
};

export const sendOtpApi = async (data) => {
  const endpoint = `/otp/send/`;
  try {
    const res = await protectedApi.post(endpoint, data);
    return formatApiResponse(res);
  } catch (e) {
    return formatApiResponse(e);
  }
};

export const verifyOtpApi = async (data) => {
  const endpoint = `/otp/verify/`;
  try {
    const res = await protectedApi.post(endpoint, data);
    return formatApiResponse(res);
  } catch (e) {
    return formatApiResponse(e);
  }
};

export const resendOtpApi = async (data) => {
  const endpoint = `/otp/resend/`;
  try {
    const res = await protectedApi.post(endpoint, data);
    return formatApiResponse(res);
  } catch (e) {
    return formatApiResponse(e);
  }
};

export const updateEmailApi = async (data) => {
  const endpoint = `/users/update-email/`;
  try {
    const res = await protectedApi.put(endpoint, data);
    return formatApiResponse(res);
  } catch (e) {
    return formatApiResponse(e);
  }
};

export const checkEmailApi = async (data,old_email='') => {
  const endpoint = `/users/validate-email/?email=${data}&old_email=${old_email}`;
  try {
    const res = await protectedApi.get(endpoint);
    return formatApiResponse(res);
  } catch (e) {
    return formatApiResponse(e);
  }
};

export const activateUserApi = async (uid, token, type) => {
  const endpoint = `/users/activate-user?uid=${uid}&token=${token}&type=${type}`;
  try {
    const res = await api.get(endpoint);
    return formatApiResponse(res);
  } catch (e) {
    return formatApiResponse(e);
  }
}

export const sendContactUsMailApi = async (mailData) => {
  const endpoint = `/clinics/send-contact-us-mail/`;
  try {
    const response = await api.post(endpoint, mailData);
    return formatApiResponse(response);
  } catch (error) {
    return formatApiResponse(error);
  }
};