import { env } from '@config/env';
import { getAuthToken } from '@contexts/authContext';
import axios from 'axios';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '@services/auth';


export const api = axios.create({
  baseURL: env.apiURL
});

export const protectedApi = axios.create({
  baseURL: env.apiURL
});

protectedApi.interceptors.request.use((request) => {
  const token = getAuthToken();
  if (!token) {
    throw new Error('Token not found in the storage');
  }
  request.headers['Authorization'] = `Bearer ${token}`;
  return request;
});

protectedApi.interceptors.response.use(
  (response) => response,
  (error) => {
    // console.error('interceptor.error', error);
    if (error && axios.isAxiosError(error)) {
      if(error.response.data.code === 401 && error.response.data.detail === "Token is expired or invalid"){
        window.localStorage.removeItem(ACCESS_TOKEN_KEY);
        window.localStorage.removeItem(REFRESH_TOKEN_KEY);
        window.location.reload();
      }
      // check whether the response return 403 and the access-token expiry message which means the access-token is expired
      console.error('interceptor.error', error);
    }
    return Promise.reject(error);
  }
);
