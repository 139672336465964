import styled from 'styled-components/macro';
import { colors, breakpoints, flex, textSizes, fonts } from '@styles/vars';

const navItem = `
    display: inline-flex;
    align-items: center;
    text-transform: capitalize;
    font-size: ${textSizes['16']};
    gap: 18px;
    font-family: ${fonts.body};
    .icon {
        color: ${colors.gray};
        transition: color var(--transition);
    }
    &:hover, &:focus,
    &.active {
        .icon {
            color: ${colors.blue};
        }
    }
`;

export const Header = styled.div`
  margin: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${breakpoints.tablet} {
    button {
      display: none;
    }
  }
`;

export const List = styled.ul`
  ${flex.col}

  ${breakpoints.tablet} {
    height: 100%;

    .pin-down {
      margin-top: auto;
    }
  }
`;

export const MainItem = styled.div`
  ${navItem};
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 45px;
  font-size: large;

  button {
    ${navItem};
    font-size: large;
    transition: none;
  }

  ${breakpoints.tablet} {
    font-size: unset;

    button {
      font-size: unset;
    }
  }

  &:hover, &:focus,
  &:active{
    text-decoration: none;
  }

  &.active{
    background-color: ${colors.blue};
    color: ${colors.white};
    .icon{
      color: ${colors.white};
    }
  }
`;

export const LinksList = styled.ul`
  ${flex.col}
  gap: 16px;
  margin: 16px 0 0 36px;
  font-size: ${textSizes['16']};

  a {
    transition: color var(--transition);

    &:hover,
    &:focus {
      color: ${colors.blue};
    }
  }

  ${breakpoints.tablet} {
    font-size: ${textSizes['14']};
  }
`;
