import styled from 'styled-components';
import ResetPasswordForm from '@widgets/ResetPassword/Forms';
import ImageContainer from '@widgets/LoginImage';
import backgroundImage from '@assets/images/login-background.jpg';
import { colors, breakpoints } from '@styles/vars';
import useWindowSize from '@hooks/useWindowSize';

const RootWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  min-height: 100vh;
  background-size: cover;
  background-repeat: none;
  background-image: url(${backgroundImage});
`;
const Container = styled.div`
maring: 0px 30px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
height: 100%;
width: ${(props)=>props.isMobile ? '90% !important' : '40%'};
padding: 25px 2px;
z-index:1;

box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
width: 40%;
border-radius: 25px;
background: ${colors.white};
`;
const StyledHeader = styled.h1`
  color: ${colors.dark};
`;
export default function AddPassword() {
  const { width } = useWindowSize();
  const isMobile = width < 768
  return (
    <RootWrapper>
      <ImageContainer />
      <Container isMobile={isMobile}>
        <StyledHeader>Change Password</StyledHeader>
        <ResetPasswordForm add_new_password="true" />
      </Container>
    </RootWrapper>
  );
}
