import styled from 'styled-components';
import { colors } from '@styles/vars';
import ResetPasswordForm from './Forms';
import { breakpoints,flex } from '@styles/vars';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 15px;
  ${flex.col}
  ${breakpoints.laptop} {
    width: 50%;
  }
`;
const StyledHeader = styled.h1`
  color: ${colors.dark};
`;

export default function ResetPasswordFormContainer() {
  return (
    <Container>
        <StyledHeader>Reset Password</StyledHeader>
        <ResetPasswordForm/>
    </Container>
  )
}