import { AuthContext } from '@contexts/authContext'
import {useContext} from 'react'

const useAuth = () => {
  const ctx = useContext(AuthContext);
  if (!ctx) {
    throw new Error("useAuth() should be used inside the AuthProvider. Unexpectedly found the context to be undefined");
  }
  return ctx;
}

export default useAuth