import styled from "styled-components";
import { Link } from "react-router-dom";
import { colors } from "@styles/vars";

export const LinkButton = styled(Link)`
    background: ${colors.blue};
    color: ${colors.white};
    padding: 5px 14px;
    border-radius: 5px;
    font-size: 14px;
    font-family: sans-serif;
    cursor: pointer;
    &:hover {
        background: ${colors.blue};
        color: ${colors.white};
        text-decoration:none;
    }
`