import axios from 'axios';
import { StatusCodes } from 'http-status-codes';

export const preventDefault = () => {
  document.querySelectorAll('form').forEach((form) => {
    form.addEventListener('submit', (e) => e.preventDefault());
  });
  document.querySelectorAll('a[href="#"]').forEach((a) => {
    a.addEventListener('click', (e) => e.preventDefault());
  });
};

export function generateAlphabet() {
  return [...Array(26)].map((_, i) => String.fromCharCode(i + 97));
}

/**
 * Format the API Response
 * @param {AxiosResponse} response - axios response
 */
export function formatApiResponse(responseOrError) {
  if (axios.isAxiosError(responseOrError)) {
    return {
      isOk: false,
      data: null,
      status:
        responseOrError.response?.status ?? StatusCodes.INTERNAL_SERVER_ERROR,
      error:
        responseOrError.response?.data?.detail || responseOrError.response?.data
    };
  }
  if (responseOrError instanceof Error) {
    return {
      isOk: false,
      data: null,
      status: StatusCodes.INTERNAL_SERVER_ERROR,
      error: null
    };
  }
  return {
    isOk: true,
    ...responseOrError.data,
    status: responseOrError.status,
    error: null
  };
}
