

import { FooterContainer, PrivacyBox, StyledLink } from "./style";


function Footer({isMobile, marginTop=false}) {
    return (
        <FooterContainer isMobile={isMobile} marginTop={marginTop}>
            <PrivacyBox isMobile={isMobile} >
                <StyledLink to={'/privacy-policy'} isMobile={isMobile}>Privacy Policy</StyledLink>
                <StyledLink to={'/contact-us'} isMobile={isMobile}>Contact Us</StyledLink>
            </PrivacyBox>
        </FooterContainer>
    )
}

export default Footer