import {
  createContext,
  useMemo,
  useState,
  useEffect,
  useCallback
} from 'react';
import { loginApi } from '@services/auth';
import { getProfile } from '@services/users';
import { StatusCodes } from 'http-status-codes';
import WidgetsLoader from '@components/WidgetsLoader';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext(null);

const ACCESS_TOKEN_KEY = 'accessToken';
const REFRESH_TOKEN_KEY = 'refreshToken';

export const setAuthToken = (accessToken) => {
  window.localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
};

export const setRefreshToken = (accessToken) => {
  window.localStorage.setItem(REFRESH_TOKEN_KEY, accessToken);
};

export const getAuthToken = () => {
  return window.localStorage.getItem(ACCESS_TOKEN_KEY);
};

export const getRefreshToken = () => {
  return window.localStorage.getItem(REFRESH_TOKEN_KEY);
};

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authenticationError, setAuthenticationError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [selectedClinicId,setSelectedClinicId] = useState(undefined);
  const navigate = useNavigate();
  const bootstrapAuth = async () => {
    try {
      setIsLoading(true);
      const { isOk, data } = await getProfile();
      // set authentication value
      if (isOk) {
        setUser(data);
        if(data['user_must_change_password'] === 'false'){
          return setIsAuthenticated(true);
        }
        
      }
      return setIsAuthenticated(false);
    } catch (e) {
      console.error('bootstrapAuth.catch', e);
      setIsAuthenticated(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const token = getAuthToken();
    if(token){
      bootstrapAuth();
    }else{
      setIsAuthenticated(false);
      setIsLoading(false);
    }
  }, []);

  const loginWithEmailPassword = useCallback(
    async (email, password, keep_logged_in) => {
      try {
        setAuthenticationError('');
        const { isOk, data, status, error } = await loginApi(email, password, keep_logged_in);
        if (
          isOk &&
          data.tokens &&
          data.tokens.access_token &&
          data.tokens.refresh_token
        ) {
          const { access_token, refresh_token } = data.tokens;
          setAuthToken(access_token);
          setRefreshToken(refresh_token);
          await bootstrapAuth();
          if (data.password_change_required) {
            navigate('/add-password');
          } else {
            navigate('/');
          }
        }
        if (
          status === StatusCodes.BAD_REQUEST ||
          status === StatusCodes.UNAUTHORIZED
        ) {
          for (const [, value] of Object.entries(error)) {
            return setAuthenticationError(`${value}`, 'error');
          }
        }
        return setAuthenticationError('');
      } catch (e) {
        setAuthenticationError('Something went wrong');
        return e;
      }
    },
    [navigate]
  );

  const values = useMemo(() => {
    return {
      user,
      loginWithEmailPassword,
      isAuthenticated,
      isLoading,
      selectedClinicId,
      setSelectedClinicId,
      bootstrapAuth,
      setIsAuthenticated,
      authenticationError
    };
  }, [
    loginWithEmailPassword,
    isAuthenticated,
    isLoading,
    selectedClinicId,
    setSelectedClinicId,
    authenticationError,
    user
  ]);

  if (isLoading) {
    return <WidgetsLoader />;
  }

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};
