import { protectedApi } from '@api/index';
import { formatApiResponse } from '@utils/helpers';

export async function getProfile() {
  try {
    const res = await protectedApi.get('/users/profile');
    return formatApiResponse(res);
  } catch (e) {
    console.error('getProfileApi.catch', e);
    return formatApiResponse(e);
  }
}

export async function addUserApi(userData) {
  const endpoint = `/users/`;
  try {
    const res = await protectedApi.post(endpoint, userData);
    return formatApiResponse(res);
  } catch (e) {
    console.error('addUserApi.catch', e);
    return formatApiResponse(e);
  }
}
export async function getUsersApi(page, limit, sorting, filter) {
  try {
    let clinic_filter = filter.clinic_id
      ? `&clinic_id=${filter.clinic_id}`
      : '';
    const sort_str = sorting
      ? `&sort=${sorting.sort}&order=${sorting.order}`
      : '';
    const res = await protectedApi.get(
      `/users/?page=${page}&limit=${limit}${sort_str}${clinic_filter}`
    );
    return formatApiResponse(res);
  } catch (e) {
    console.error('getUsersApi.catch', e);
    return formatApiResponse(e);
  }
}

export async function getIndividualUserApi(userId) {
  try {
    const res = await protectedApi.get(`/users/${userId}`);
    return formatApiResponse(res);
  } catch (e) {
    console.error('getIndividualUserApi.catch', e);
    return formatApiResponse(e);
  }
}

export const editUserApi = async (id, userData) => {
  const endpoint = `/users/${id}`;
  try {
    const response = await protectedApi.put(endpoint, userData);
    return formatApiResponse(response);
  } catch (error) {
    return formatApiResponse(error);
  }
};

export async function getUserRolesListApi() {
  const endpoint = `/users/roles`;
  try {
    const response = await protectedApi.get(endpoint);
    return formatApiResponse(response);
  } catch (error) {
    return formatApiResponse(error);
  }
}

export async function editIndividualUserStatusApi(data) {
  const endpoint = `/users/status/`;
  try {
    const response = await protectedApi.post(endpoint, data);
    return formatApiResponse(response);
  } catch (error) {
    return formatApiResponse(error);
  }
}

export async function deleteUserApi(user_id) {
  const endpoint = `/users/?user_id=${user_id}`;
  try {
    const response = await protectedApi.delete(endpoint);
    return formatApiResponse(response);
  } catch (error) {
    return formatApiResponse(error);
  }
}
