export const handleKeys = (event)=> {
  if (event.charCode >= 48 && event.charCode <= 57 || event.charCode === 43 || event.charCode === 45){
    return true;
  }else{
    event.preventDefault();
  }
}

export const formatNumberInUs = (number) => {
  if(number){
    const cleanedNumber = number.replace(/\D/g, ''); // Remove all non-digit characters
    if (/^\+?1(\d{3})(\d{3})(\d{4})$/.test(cleanedNumber)) {
      return cleanedNumber.replace(/^(\+?1)(\d{3})(\d{3})(\d{4})$/, '+$1 ($2) $3-$4');
    }
    return number; // Return the original input if it doesn't match the expected format
  }
}

export const generateShades = (colorCode) => {
  // Parse the input color code
  const hexColor = colorCode.replace('#', '');
  const r = parseInt(hexColor.slice(0, 2), 16);
  const g = parseInt(hexColor.slice(2, 4), 16);
  const b = parseInt(hexColor.slice(4, 6), 16);

  // Calculate lighter shades
  const lightest = `#${Math.floor(r * 0.8).toString(16)}${Math.floor(g * 0.8).toString(16)}${Math.floor(b * 0.8).toString(16)}`;
  const lighter = `#${Math.floor(r * 0.65).toString(16)}${Math.floor(g * 0.65).toString(16)}${Math.floor(b * 0.65).toString(16)}`;
  const light = `#${Math.floor(r * 0.35).toString(16)}${Math.floor(g * 0.35).toString(16)}${Math.floor(b * 0.35).toString(16)}`;

  // Construct the final format
  const result = `(90deg, ${lightest} 0%, ${lighter} 35%, ${light} 100%)`;
  return result;
}

// Example usage
// const inputColor = '#ff0000';
// const shadesResult = generateShades(inputColor);
// console.log('Generated shades:', shadesResult);

