import React from 'react'
import { Heading, ContentWrapper, Paragraph, OrderedList, List, Paragraph2, UnorderedDiscList, PrivacyPolicyContainer, DiscList, Anchor } from './style'
import useWindowSize from '@hooks/useWindowSize'

function PrivacyPolicyDetail() {
    const { width } = useWindowSize();
    const isMobile = width < 768

    return (
        <ContentWrapper isMobile={isMobile}>
            <Heading>
                Privacy Policy
            </Heading>
            <Paragraph>
                Effective Date : June, 10 2024
            </Paragraph>
            <PrivacyPolicyContainer>
                <Paragraph>
                    <OrderedList>
                        {/* INTRODUCTION */}
                        <List>Introduction</List>
                        <Paragraph2>
                            Welcome to ForeverOneApp (“we,” “our,” “us”). We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, and protect your information when you use our web application.
                        </Paragraph2>
                        {/* INFORMATION WE COLLECT */}
                        <List>Information We Collect</List>
                        <Paragraph2>
                            We are dedicated to minimizing the amount of personal information we collect. We do not use cookies or collect personal IP addresses. The types of information we may collect include:
                            <UnorderedDiscList>
                                <DiscList>
                                    <b>Personal Information: </b>
                                    Information that you voluntarily provide to us, such as your name, email address, and any other details you choose to share when contacting us or using certain features of our application.
                                </DiscList>
                            </UnorderedDiscList>
                        </Paragraph2>
                        {/* How We Use Your Information */}
                        <List>How We Use Your Information</List>
                        <Paragraph2>
                            We use the collected information for the following purposes:
                            <UnorderedDiscList>
                                <DiscList><b>To Provide and Maintain Our Service: </b>To ensure the functionality and performance of our application.</DiscList>
                                <DiscList><b>To Communicate with You: </b>To send you updates and respond to your inquiries.</DiscList>
                                <DiscList><b>To Improve Our Service: </b>To understand how our application is used and make improvements based on user feedback.</DiscList>
                            </UnorderedDiscList>
                        </Paragraph2>
                        {/* Sharing Your Information */}
                        <List>Sharing Your Information</List>
                        <Paragraph2>
                            We do not share your personal information with third parties except in the following circumstances:
                            <UnorderedDiscList>
                                <DiscList><b>Service Providers: </b>We may share your information with trusted third-party service providers who assist us in operating our application and providing services to you.</DiscList>
                                <DiscList><b>Legal Requirements: </b>We may disclose your information if required by law or in response to valid requests by public authorities.</DiscList>
                                <DiscList><b>Business Transfers: </b>In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of the transaction.</DiscList>
                            </UnorderedDiscList>
                        </Paragraph2>
                        {/* Security of Your Information */}
                        <List>Security of Your Information</List>
                        <Paragraph2>
                            We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.
                        </Paragraph2>
                        {/* Your Rights and Choices */}
                        <List>Your Rights and Choices</List>
                        <Paragraph2>
                            You have certain rights regarding your personal information:
                            <UnorderedDiscList>
                                <DiscList><b>Access and Correction: </b>You can access and update your personal information by contacting us.</DiscList>
                                <DiscList><b>Opt-Out: </b>You can opt out of receiving promotional communications from us by following the unsubscribe instructions provided in those communications.</DiscList>
                                <DiscList><b>Data Deletion: </b>You can request the deletion of your personal information, subject to certain exceptions provided by law.</DiscList>
                            </UnorderedDiscList>
                        </Paragraph2>
                        {/* Changes to This Privacy Policy */}
                        <List>Changes to This Privacy Policy</List>
                        <Paragraph2>
                            We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
                        </Paragraph2>
                        <List>Contact Us</List>
                        <Paragraph2>
                            If you have any questions or concerns about this Privacy Policy, please contact us at: <Anchor href="mailto:admin@securesoftwareexperts.com" target='foreveroneapp://mailto:admin@securesoftwareexperts.com'>admin@securesoftwareexperts.com</Anchor>.
                            <br/>
                            By using our web application, you agree to the terms of this Privacy Policy. Thank you for trusting us with your personal information.
                        </Paragraph2>
                    </OrderedList>
                </Paragraph>
            </PrivacyPolicyContainer>


        </ContentWrapper>
    )
}

export default PrivacyPolicyDetail