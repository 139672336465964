import styled from "styled-components";
import { colors } from "@styles/vars";
import { Link } from "react-router-dom";

export const FooterContainer = styled.div`
    z-index:1;
    width:100%;
    background: ${(props)=> props.isMobile ? colors.transparentColor : colors.footerBackground };
    margin-top: ${(props)=> props.marginTop ? props.marginTop : null};
`

export const PrivacyBox = styled.div`
  font-size: 14px;
  padding: 6px 20px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 100%;
  display: flex;
  justify-content: ${(props)=> props.isMobile ? 'space-around' : 'flex-end'};
  gap: ${(props)=> props.isMobile ? '0px' : '20px'};
  
`
export const StyledLink = styled(Link)`
  color: ${(props)=> props.isMobile ? colors.white : colors.white};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    color: ${(props)=>props.isMobile ? colors.white : '#b6caf8'};
  }
`