// styling
import styled from 'styled-components/macro';
import { flex, textSizes, colors } from '@styles/vars';
import Typography from '@mui/material/Typography';
// utils
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';

const Wrapper = styled.div`
  ${flex.col};
  margin-top: ${(props) => props.marginTop ? props.marginTop : '20px'};
  width: 100%;
`;

const InputError = styled.div`
  color: ${colors.error};
  margin-top: 10px;
  margin-bottom: 5px;
  font-size:${(props)=>props.isMobile ? '14px' : '16px'}
`;

const formatError = (message) => {
  return message.length > 0 ? message[0].toUpperCase() + message.slice(1) : '';
};

const LabeledFormInput = ({
  id,
  title,
  type = 'text',
  placeholder,
  value = '',
  isTouched = false,
  error = '',
  customInput,
  placeholder_text = '',
  disabled = false,
  fontSize = '',
  required,
  styledProps,
  isMobile=false,
  ...restProps
}) => {
  const label = title ? title : '';
  return (
    <Wrapper width={styledProps ? styledProps.width : null} marginTop={styledProps ? styledProps.marginTop : null} style={styledProps ? styledProps : null}>
      <TextField
        id={id}
        label={label ?
          <Typography component="div">
            {label} {required ? <sup>*</sup> : null}
          </Typography>:null
        }
        variant="standard"
        value={value}
        disabled={disabled}
        placeholder={placeholder_text?placeholder_text:null}
        type={type}
        sx={{
          '& .MuiInput-input': {
            fontSize: fontSize ? fontSize : ''
          }
        }}
        {...restProps}
      />
      {error.length > 0 && <InputError isMobile={isMobile}>{formatError(error)}</InputError>}
    </Wrapper>
  );
};

LabeledFormInput.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    'text',
    'password',
    'email',
    'number',
    'datetime-local',
    'date'
  ]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  customInput: PropTypes.element,
  required: PropTypes.bool
};

export default LabeledFormInput;
