import styled from 'styled-components/macro';
import { breakpoints, flex } from '@styles/vars';

export const Content = styled.div`
  padding: 10px 15px 84px;
  height: auto;
  width: 100%;
  display: flex;

  ${breakpoints.tablet} {
    padding: 0px 30px 30px 30px;
    ${flex.col};
  }

  ${breakpoints.laptopL} {
    padding: 0;
    margin-top: 0;
  }

  @media screen and (min-width: 1280px) and (min-height: 800px) {
    overflow: hidden;
  }
`;
export const ContentTitle = styled.h1`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 18px;
  margin-left:${(props)=>props.title === 'Home' ? '6px' : null};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 20px;
  ${'' /* margin-bottom: 12px; */}
  padding-bottom: 10px;
  ${breakpoints.laptopL} {
    margin-top: 12px;
  }

  ${breakpoints.desktop} {
    flex-direction: row;
    ${flex.between};
  }
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding-right: 16px;
`;

export const Widgets = styled.div`
  ${flex.col};
  width: 100%;
  flex-grow: 1;
`;
