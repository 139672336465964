import styled from 'styled-components';
import FormContainer from '@widgets/ForgotPasswordForm';
import ImageContainer from '@widgets/LoginImage';
import backgroundImage from '@assets/images/login-background.jpg';
import chiroMobileBackground from '@assets/images/chiro-login-mobile-background.png';
import { breakpoints } from '@styles/vars';
import useWindowSize from '@hooks/useWindowSize';
import Footer from '@layout/Footer';
import Navbar from '@layout/Navbar';

const RootWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 100%;
  // &:before {
  //   content: '';
  //   width: 100%;
  //   height: 100%;
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   background-size: cover;
  //   background-repeat: none;
  //   z-index: 0;
  //   background-position: center center;
  //   transform: scale(1.1);
  // }
  ${breakpoints.laptop} {
    background-repeat: no-repeat;
    background-image: url(${backgroundImage});
    background-size: cover;
    width: 100%;
    height: 100%;
    &:before: {
      background: none;
      display: none;
      width: 0px;
      height: 0px;
    }
  }
`;
const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position:absolute;
  height:100% !important;
  width:100%;
  background-image: ${(props) => props.isMobile ? `url(${chiroMobileBackground})` : null};
  background-size: cover;
  
`

export default function ForgotPassword() {
  const { width } = useWindowSize();
  const isMobile = width < 768;

  return (
    <ComponentWrapper isMobile={isMobile}>
      <Navbar isMobile={isMobile} logoColor={'#FFFFFF'} />
      <RootWrapper isMobile={isMobile}>
        <ImageContainer />
        <FormContainer isMobile={isMobile} />
      </RootWrapper>
      <Footer isMobile={isMobile} />
    </ComponentWrapper>
  );
}
