import YouTube from 'react-youtube';
import { ListItem, ListItem1 } from './style';

function VideoTutorials({ isMobile,videoOptions, onPlay, onReady, videos }) {

    return (
        <div>
            {videos.map((video, index) => (
                index === 0 ? null :
                <div key={video.id}>
                    <ListItem style={{ marginTop: index === 1 ? '0' : '20px' }}>
                        {index}. {video.title}
                    </ListItem>
                    <ListItem1>
                        <YouTube
                            videoId={video.id}
                            opts={videoOptions}
                            onPlay={onPlay}
                            onReady={(event) => onReady(event, index)}
                        />
                    </ListItem1>
                </div>
            ))}
        </div>
    );
}

export default VideoTutorials;
