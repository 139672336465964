import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import useWindowSize from '@hooks/useWindowSize';
import { useNavigate } from 'react-router';
import { colors } from '@styles/vars';
import { LinkButton } from './style';

// const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function Navbar({ isMobile, is_visible = true, logoColor = 'black' }) {
    const navigate = useNavigate();

    return (
        <AppBar position="static" sx={{ background: isMobile ? colors.transparentColor : colors.aliceblue, marginBottom: '0px !important'}}>
            <Container maxWidth="xl" sx={{zIndex:'100000'}}>
                <Toolbar disableGutters sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    {/* <AdbIcon /> */}
                    <Typography
                        variant="h6"
                        noWrap
                        component="span"
                        onClick={() => navigate('/')}
                        sx={{
                            mr: 2,
                            fontWeight: 600,
                            color: `${logoColor && isMobile ? logoColor  : colors.black} !important`,
                            textDecoration: 'none',
                            cursor: 'pointer',
                        }}
                    >
                        ForeverOneApp
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        {is_visible && <LinkButton
                            to={'/login'}
                        >
                            Login
                        </LinkButton>}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default Navbar;
