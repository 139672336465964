// utils
import { lazy, Suspense, useEffect, useRef } from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';
import WidgetsLoader from '@components/WidgetsLoader';

// hooks
import usePageIsOverflow from '@hooks/usePageIsOverflow';
import PageNotFound from '@pages/PageNotFound';
import ForgotPassword from '@pages/ForgotPassword';
import ResetPassword from '@pages/ResetPassword';
import AddPassword from '@pages/AddPassword';
import WebsiteTutorial from '@pages/WebsiteTutorial';
import ContactUsPublic from '@pages/ContactUsPublic';
import PrivacyPolicyPublic from '@pages/PrivacyPolicyPublic';

// pages
const LoginPage = lazy(() => import('@pages/Login'));
const ActivateUser = lazy(() => import('@pages/ActivateUser'));

const AuthLayout = () => {
  const appRef = useRef(null);

  useEffect(() => {
    if (appRef.current) {
      appRef.current.scrollTo(0, 0);
    }
  }, []);

  return (
      <Suspense fallback={<WidgetsLoader />}>
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/add-password" element={<AddPassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/password-reset/:uid/:token/" element={<ResetPassword />} />
          <Route path="/app-tutorial" element={<WebsiteTutorial />} />
          <Route path="/activate-user" element={<ActivateUser />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPublic />} />
          <Route path="/contact-us" element={<ContactUsPublic />} />
          <Route path="/404" element={<PageNotFound />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </Suspense>
  );
};

export default AuthLayout;
