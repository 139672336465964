// components

// styling
import { QtyBadge } from '@components/UI/Badge/style';
import styled from 'styled-components/macro';
import theme from 'styled-theming';
import { dark, light, flex, colors } from '@styles/vars';

// utils
import PropTypes from 'prop-types';

const bg = theme('theme', {
  light: light.bodyBg,
  dark: dark.highlight
});

export const Button = styled.button`
  width:${props => props.width || '40px'};
  aspect-ratio: 1;
  background-color: ${bg};
  color: ${colors.gray};
  ${flex.col}
  ${flex.center}
  position: relative;
  transition: color var(--transition), background-color var(--transition);

  &:hover,
  &:focus {
    background-color: ${colors.blue};
    color: #fff;
  }

  .badge {
    position: absolute;
  }

  &.square {
    border-radius: 8px;
    .badge {
      top: -6px;
      right: -6px;
    }
  }

  &.round {
    border-radius: 50%;
    .badge {
      top: 0;
      right: 0;
    }
  }
  &.no_shape {
    background: none !important;
    border: none;
    padding: 0 !important;
    /*optional*/
    font-family: arial, sans-serif;
    /*input has OS specific font-family*/
    color: #069;
    text-decoration: underline;
    cursor: pointer;
    width: 120px !important;
    height: 50px !important;
    margin-bottom: 0px !important;
  }
`;

const ShapeButton = ({
  text,
  isVisiable,
  hasNotification,
  icon,
  handler,
  label,
  shape,
  badgeCount,
  width,
  ...props
}) => {
  return (   
    <>
      <Button
        width={width}
        className={shape}
        onClick={handler}
        aria-label={label}
        ref={props.ref}
        // style={isVisiable ? { display: 'none' } : { display: 'block' }}
        {...props}
      >
        <i className={`icon-${icon}`}></i>
        {badgeCount ? (
          <QtyBadge className="badge" color="red">
            {badgeCount}
          </QtyBadge>
        ) : (
          ''
        )}
      </Button>
    </>
  );
};

ShapeButton.propTypes = {
  text: PropTypes.string,
  isVisiable: PropTypes.bool,
  hasNotification: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  handler: PropTypes.func,
  label: PropTypes.string.isRequired,
  shape: PropTypes.oneOf(['round', 'square', 'no_shape']).isRequired
};

export default ShapeButton;
