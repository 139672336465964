import styled from "styled-components";
import { colors } from "@styles/vars";

export const IFrameWrapper = styled.div`
    margin-top: ${(props)=>props.isMobile ? '0px' : '20px'};
    height:100%;
`
export const Heading1 = styled.div`
    font-size: ${(props)=>props.isMobile ? '18px' : '24px'};
    font-weight: 600;
`

export const ContentHeading = styled.div`
    font-size: ${(props)=>props.isMobile ? '13px' : '14px'};
    font-weight: 600;
`
export const TopicHeading = styled.div`
    font-size: ${(props)=>props.isMobile ? '13px' : '14px'};
    font-weight:600;
`
export const ListItem = styled.div`
    font-size: ${(props)=>props.isMobile ? '13px' : '14px'};
    margin:3px 0px;
`

export const ListItem1 = styled.div`
    font-size: ${(props)=>props.isMobile ? '13px' : '14px'};
    margin: 3px 0px 3px 10px;
`

export const ListItem2 = styled.div`
    font-size: ${(props)=>props.isMobile ? '13px' : '14px'};
    margin: 3px 0px 3px 20px;
`
export const Image = styled.img`
    margin: 10px 0px;
    border: 1px solid #1e1e1e;
    border-radius: 6px;
`

export const MobileImageInMobile = styled.img`
    margin: 10px 0px;
    width:70%;
`
export const MobileImageInDesktop = styled.img`
    margin: 10px 0px;
    width:25%;
`
export const IFrameDemoVideo = styled.iframe`
    width: ${(props)=>(props.isMobile ? '100%' : '560px')};
    height: ${(props)=>(props.isMobile ? '200px' : '310px')};
    referrer-policy: strict-origin-when-cross-origin;
`
export const LinkContainer = styled.p`
    font-size: ${(props)=>props.isMobile ? '13px' : '14px'};
    margin: 10px 0px;
`
export const Link = styled.a`
    color: ${colors.blue};
    cursor: pointer;
`