import styled from "styled-components";
import FormContainer from '@widgets/ResetPassword';
import ImageContainer from '@widgets/LoginImage';
import backgroundImage from '@assets/images/login-background.jpg';
import chiroMobileBackground from '@assets/images/chiro-login-mobile-background.png';
import { breakpoints } from '@styles/vars';

const RootWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  min-height: 100vh;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    background-repeat: none;
    z-index: -100;
    background-position: center center;
    transform: scale(1.1);
  }
  ${breakpoints.laptop} {
    background-repeat: no-repeat;
    background-image: url(${backgroundImage});
    background-size: cover;
    width: 100%;
    height: 100%;
    &:before: {
      background: none;
      display: none;
      width: 0px;
      height: 0px;
    }
  }
`;
export default function ResetPassword() {
    return (
      <RootWrapper>
      <ImageContainer/>
        <FormContainer/>
      </RootWrapper>
    );
  }