// assets
import logo from '@assets/logo.svg';

// styling
import styled from 'styled-components/macro';
import { colors, fonts, textSizes, breakpoints } from '@styles/vars';
import theme from 'styled-theming';
import { TbPhysotherapist } from "react-icons/tb";
import useWindowSize from '@hooks/useWindowSize';

// components
import { NavLink } from 'react-router-dom';

// utils
import PropTypes from 'prop-types';

const Img = styled.img`
  width: 30px;
  height: 30px;
  will-change: transform;
  transition: transform var(--transition);
`;

const Wrapper = styled.a`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 5px;

  &:hover,
  &:focus {
    text-decoration: none;
    color: inherit;
    Img {
      transform: scale(1.2);
    }
  }
`;

const Text = styled.span`
  font-weight: 700;
  font-family: ${fonts.accent};
  font-size: ${textSizes[15]};
  // color:#7cc2ff;
  .highlight {
    color: ${theme('theme', {
      light: colors.blue,
      dark: 'inherit'
    })};
  }

  ${breakpoints.tablet} {
    font-size: ${textSizes[24]};
  }
`;

const Logo = (props, { compact }) => {
  const { width } = useWindowSize();
  const isMobile = width < 768;

  return (
    <Wrapper as={NavLink} to={props.userRole ? '/home' : '/'} className="logo">
      {/* <TbPhysotherapist style={{fontSize: '30px',color: '#5d87ff',marginLeft:'-6px'}} /> */}
      {/* <Img src={chirologo} alt="LeadSoft" /> */}
        <Text isMobile={isMobile}>
          ForeverOneApp 
          {/* Chiro<span className="highlight">Lead</span> */}
        </Text>
      {/* {!compact ? (
        <Text style={{ display: props.hideText ? 'none' : '' }}>
          ForeverOneApp 
          Chiro<span className="highlight">Lead</span>
        </Text>
      ) : null} */}
    </Wrapper>
  );
};

Logo.propTypes = {
  compact: PropTypes.bool
};

export default Logo;
