import { motion } from 'framer-motion';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BottomMenuButtons } from '@constants/menu';
import useAuth from '@hooks/useAuth';

// styled components
import { Container } from './style';

const BottomMenu = (props) => {
  const [selectedValue, setSelectedValue] = useState('Home');
  const [scrollDir, setScrollDir] = useState('up');
  const navigate = useNavigate();
  const { user } = useAuth();
  const location = window.location.href;

  const handleChange = (event, newValue) => {
    setSelectedValue(newValue);
  };

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? 'down' : 'up';
      if (direction !== scrollDir) {
        setScrollDir(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };

    window.addEventListener('scroll', updateScrollDirection);
    return () => {
      window.removeEventListener('scroll', updateScrollDirection);
    };
  }, [scrollDir]);

  const tabsStyles = {
    width: '100%',
    '& .MuiTabs-indicator': {
      top: 0,
      bottom: 'unset',
      backgroundColor: '#000000', // Change the upper line to black on selected tab
    },
    '& .MuiTabs-flexContainer': {
      justifyContent: 'space-between',
    },
  };

  const tabStyles = {
    minWidth: 0,
    flexGrow: 1,
    '&.Mui-selected': {
      color: '#000000', // Dark black icon color when selected
    },
    '& .MuiTab-iconWrapper': {
      transition: 'transform 0.3s ease', // Smooth transition for tilt effect
    },
    // '&.Mui-selected .MuiTab-iconWrapper': {
    //   transform: 'rotate(-10deg)', // Slight tilt when selected
    // },
  };

  useEffect(() => {
    console.warn("yeah");
     if(location.includes('home')){
      setSelectedValue('Home');
     }else if(location.includes('leads')){
      setSelectedValue('Leads');
     }else if(location.includes('users')){
      setSelectedValue('Users');
     }else if(location.includes('templates')){
      setSelectedValue('Templates');
     }else if(location.includes('clinics')){
      setSelectedValue('Clinics');
     }else{
      setSelectedValue(false);
     }
  }, [location]);

  return (
    <Container
      as={motion.div}
      initial={{ y: '0%' }}
      animate={{ y: scrollDir === 'down' ? '100%' : '0%' }}
      transition={{ duration: 0.3, ease: 'linear', type: 'tween' }}
    >
      <Tabs
        value={selectedValue}
        onChange={handleChange}
        variant="fullWidth"
        sx={tabsStyles}
      >
        {BottomMenuButtons.filter((menu) => menu.role.includes(user.role.toLowerCase())).map((item, index) => (
          <Tab
            value={item.name}
            key={index}
            onClick={() => navigate(item.link)}
            icon={item.name === selectedValue ? item.selectedIcon : item.icon}
            label={<span style={{ fontSize: '0.75rem' }}>{item.name}</span>}
            sx={tabStyles}
          />
        ))}
      </Tabs>
    </Container>
  );
};

export default BottomMenu;
