// utils
import { lazy, Suspense } from 'react';

// components
import ScrollProgress from '@components/UI/ScrollProgress';
import Panel from '@layout/Panel';
import Sidebar from '@layout/Sidebar';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import BottomMenu from '@layout/BottomMenu';
import WidgetsLoader from '@components/WidgetsLoader';

// hooks
import useWindowSize from '@hooks/useWindowSize';
import usePageIsOverflow from '@hooks/usePageIsOverflow';
import { useRef, useEffect } from 'react';
import useAuth from '@hooks/useAuth';
import { menu } from '@constants/menu';

// pages

const DashboardA = lazy(() =>
  /* webpackPrefetch: true */ import('@pages/DashboardA')
);
const DashboardB = lazy(() => import('@pages/DashboardB'));
const DashboardC = lazy(() => import('@pages/DashboardC'));
const DashboardD = lazy(() => import('@pages/DashboardD'));
const DashboardE = lazy(() => import('@pages/DashboardE'));
const DashboardF = lazy(() => import('@pages/DashboardF'));
const DashboardG = lazy(() => import('@pages/DashboardG'));
const DashboardH = lazy(() => import('@pages/DashboardH'));
const DashboardI = lazy(() => import('@pages/DashboardI'));
const DashboardJ = lazy(() => import('@pages/DashboardJ'));
const DashboardK = lazy(() => import('@pages/DashboardK'));
const DoctorAppointments = lazy(() => import('@pages/DoctorAppointments'));
const PatientAppointments = lazy(() => import('@pages/PatientAppointments'));
const Patients = lazy(() => import('@pages/Patients'));
const Tests = lazy(() => import('@pages/Tests'));
const Doctors = lazy(() => import('@pages/Doctors'));
const StaffList = lazy(() => import('@pages/Staff'));
const DoctorMessenger = lazy(() => import('@pages/DoctorMessenger'));
const PatientMessenger = lazy(() => import('@pages/PatientMessenger'));
const DoctorsReviews = lazy(() => import('@pages/DoctorsReviews'));
const PatientReviews = lazy(() => import('@pages/PatientReviews'));
const Finances = lazy(() => import('@pages/Finances'));
const PageNotFound = lazy(() => import('@pages/PageNotFound'));
const ActivateUser = lazy(() => import('@pages/ActivateUser'));
const PrivacyPolicy = lazy(() => import('@pages/PrivacyPolicy'));
const Contact = lazy(() => import('@pages/Contact'));
const CustomSidebar = lazy(() => import('@layout/CustomSidebar'));

const AppLayout = (props) => {
  const appRef = useRef(null);
  const isOverflow = usePageIsOverflow();
  const { width } = useWindowSize();
  const { user,setSelectedClinicId } = useAuth();
  // const [clinicId,setUserId] = useState();
  const isMobile = width < 768;
  let location = useLocation();

  useEffect(() => {
    if(location.pathname!=='/leads' && location.pathname!=='/home'){
      setSelectedClinicId(undefined);
    }
  })

  useEffect(() => {
    if (appRef.current) {
      appRef.current.scrollTo(0, 0);
    }
  }, []);

  const showBottomNavBar = [
    '/leads',
    '/clinics',
    '/users',
    '/integration',
    '/home',
    '/templates',
    // '/notification',
    '/faq',
    '/app-tutorial',
    '/privacy-policy',
    '/add-phrases',
    '/contact-us',
  ];

  return (
    <div className="app" ref={appRef}>
      {isOverflow ? <ScrollProgress /> : null}
      <CustomSidebar open={props.open} setOpen={props.setOpen} userRole={user.role.toLowerCase()} />
      <div className="app_content">
        <Suspense fallback={<WidgetsLoader />}>
          <Routes>
            <Route
              path="/"
              element={
                <Navigate to={user.role.toLowerCase() ? '/home' : '/'} />
              }
            />
            {menu
              .filter((m) => m.role.includes(user.role.toLowerCase()))
              .map((route, index) => (
                <Route
                  path={route.link}
                  key={index}
                  element={route.component}
                />
              ))}
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/activate-user" element={<ActivateUser />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/404" element={<PageNotFound />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Suspense>
      </div>

      {showBottomNavBar.includes(location.pathname) && isMobile ? (
        <BottomMenu user={user} />
      ) : null}
    </div>
  );
};

export default AppLayout;
