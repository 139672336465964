// components
import Logo from '@components/UI/Logo';
import MenuButton from '@components/UI/MenuButton';
import ShapeButton from '@components/UI/ShapeButton';
import { motion } from 'framer-motion';
import CurrentUser from '@layout/Panel/CurrentUser';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

// hooks
import useWindowSize from '@hooks/useWindowSize';
import usePanelScroll from '@hooks/usePanelScroll';
import { useSidebarContext } from '@contexts/sidebarContext';
import { useRef, useEffect } from 'react';
// import { useNotification } from '@hooks/useNotification';
// import { useWebsocket } from '@hooks/useWebsocket';
// import NotificationWidget from '@widgets/NotificationWidget';
import { ContentTitle } from '@layout/Page/style';
import { QtyBadge } from '@components/UI/Badge/style';
import Grid from '@mui/material/Grid';
import SearchBar from '@widgets/SearchWidget';
import { colors } from '@styles/vars';
import { Actions, Header, LinksWrapper } from './style';
import BreadCrumb from '@widgets/BreadCrumbs';

const EnableBreadsCrumbPages = [
  "Profile",
  "Change Email",
  "Change Password",
  "Contact Us",
  "Privacy Policy",
  "Add Template",
  "Edit Template",
  "Add User",
  "Edit User",
  "Add Clinic",
  "Edit Clinic",
  "Edit Lead",
  "Edit API Integration Details",
  "Add Phrases",
  "FAQs",
  "Tutorial"
]

const Panel = (props) => {
  const host = window.location.origin;
  const { width } = useWindowSize();
  const isMobile = width < 768;
  const laptopL = width < 1279;
  const isDesktop = width >= 1366;
  const classname = usePanelScroll();
  const { isSidebarOpen } = useSidebarContext();
  const headerRef = useRef(null);
  // const { setNotificationCount, setNotifications, getNotifications } =
  //   useNotification();
  // const { status, setStatus, getClientObj } = useWebsocket();
  const navigate = useNavigate();

  const MenuLogo = {
    width: '100px',
    display: 'flex',
    justifyContent: isMobile ? 'flex-start' : 'space-evenly',
    alignItems: 'center'
  };

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--header-height',
      `${headerRef.current.offsetHeight}px`
    );
  }, [width]);

  // // get notification list on first time
  // useEffect(() => {
  //   if (props.loggedInUserDetail.role === 'Admin') {
  //     getNotifications();
  //   }
  // }, []);

  // // on load connect with client
  // useEffect(() => {
  //   if (props.loggedInUserDetail.role === 'Admin') {
  //     const client = getClientObj(props.loggedInUserDetail.clinic_id);
  //     const websocketClient = client.connect({
  //       onMessageHandler: async (msg_data) => {
  //         // here we'll check if there's a new notification coming
  //         const dataArr = msg_data.message;
  //         setNotificationCount(dataArr.notif_count);
  //         setNotifications(dataArr.data);
  //       },
  //       onCloseHandler: async () => {
  //         setStatus('closing');
  //       }
  //     });
  //     /*
  //   here our websocket status will be checked .If connection is closed ,
  //   then status will be set which inturn will re render and re connect our client
  //   */
  //     // if (status === 'closing') {
  //     //   setStatus('idle');
  //     // }

  //     // return () => {
  //     //   websocketClient.close();
  //     // };
  //   }
  // }, [status, props.loggedInUserDetail.clinic_id]);

  return (
    <Header
      ismobile={isMobile ? 1 : 0}
      laptopL={laptopL}
      as={motion.header}
      animate={{ y: isSidebarOpen && isMobile ? '-100%' : 0 }}
      transition={{ duration: 0.3, ease: 'linear', type: 'tween' }}
      className={classname}
      ref={headerRef}
      paddingleft={props.paddingLeft}
    >
      <Grid
        container
        alignItems="center"
        // spacing={2}
        justifyContent="space-between"
        direction="row"
        sx={{ background: colors.aliceblue }}
      >
        {/* TUTORIAL HEADING */}
        {props.title !== "Tutorial" ?
          <Grid
            item
            xs={12}
            sm={12}
            style={{ fontSize: isMobile ? '12px' : '14px', textAlign: 'center', padding: '7px 1px', background: '#ffffff', borderRadius: '6px', marginBottom: isMobile ? '6px' : '20px', marginTop: width > 1279 ? '-25px' : null }}
          >
            <span> Start Exploring Our {isMobile ? 'App' : 'Website'}, To View Tutorial.&nbsp;<Link to="/app-tutorial" style={{ textDecoration: 'underline', color: colors.blue }}>Click Here</Link> </span>

          </Grid>
          : null}
        {/* LOGO IN MOBILE VIEW */}
        {
          isMobile ? (
            <Grid
              item
              xs={4}
              sm="auto"
              alignItems="center"
            >
              <Logo compact={isMobile} hideText={true} />
            </Grid>
          ) :
            null
        }
        {/* BREADSCRUMB ON CONTACT US PAGE, PRIVACY POLICY PAGE, IN DESKTOP VIEW */}
        {(EnableBreadsCrumbPages.includes( props.title)) && !isMobile ?
          <Grid
            item
            sm={props.title === 'FAQs' ? 1 : 8}
            alignItems="center"
          >
            <BreadCrumb
              isMobile={props.currentURL ? false : true}
              match={[
                { pathname: '/home', title: 'Home' },
                { pathname: props.currentURL , title: props.currentPage ? props.currentPage : '' }
              ]}
            />
          </Grid>
          :
          width > 0 && !isMobile && !!props.title && (
            <Grid
              item
              xs={props.searchMenu ? 4 : 'auto'}
              sm="auto"
              alignItems="center"
            >
              <ContentTitle title={props.title}>
                {props.title}{' '}
                {props.hasBadge && props.qty && props.qty > 0 && (
                  <QtyBadge>+{props.qty}</QtyBadge>
                )}
              </ContentTitle>
            </Grid>
          )}
      {/* IF SEARCH BAR IN DESKTOP */}
        {props.searchMenu && !isMobile ? (
          <Grid
            item
            xs={6}
            sm={6}
            alignItems="center"
          >
            <SearchBar
              isMobile={isMobile}
              Search={props.Search}
              SetSearch={props.SetSearch}
              SearchText={props.SearchText}
              setSearchText={props.setSearchText}
            />
          </Grid>
        ) : null}
        {/* FAQ & USER SECTION */}
        <Grid
          item
          xs={isMobile ? 8 : 2}
          sm={isMobile ? 8 : 2}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
          alignItems="center"
        >
          {isMobile ? (
            // <MenuButton />
            <>
              <Actions isMobile={isMobile}>
                <LinksWrapper isMobile={isMobile} style={{ marginRight: '8px' }}>
                  <Link
                    to={'/faq'}
                    style={{ textDecoration: 'underline', textUnderlineOffset: '3px' }}
                  >
                    FAQs
                  </Link>
                </LinksWrapper>
                <CurrentUser isMobile={isMobile} width={width} {...props.loggedInUserDetail} />
              </Actions>
            </>
          ) : (
            <Actions isMobile={isMobile}>
              <LinksWrapper style={{ fontSize: '14px' }}>
                <Link
                  to={'/faq'}
                  style={{ textDecoration: 'underline', textUnderlineOffset: '3px' }}
                >
                  FAQs
                </Link>
              </LinksWrapper>
              <CurrentUser width={width} {...props.loggedInUserDetail} />
            </Actions>
          )}
        </Grid>
      </Grid>
    </Header>
  );
};

export default Panel;
