import Page from '@layout/Page';
import WebsiteTutorialDetail from '@widgets/WebsiteTutorialDetail';
import useAuth from '@hooks/useAuth';
import Navbar from '@layout/Navbar';
import useWindowSize from '@hooks/useWindowSize';
function WebsiteTutorial() {
    const pageTitle = "Tutorial"
    const { user } = useAuth();
    const { width } = useWindowSize();
    const isMobile = width < 768;
    if (!user || user.user_must_change_password === "true") {
        return (
            <div style={{padding: isMobile ? '2px 10px' : '2px 25px'}}>
                <Navbar />
                <WebsiteTutorialDetail/>
            </div>
        )
    }
    return (
        <Page title={pageTitle} mobileHideTopBar={false} currentURL={`/app-tutorial`} currentPage={pageTitle} paddingLeft="13px">
            <WebsiteTutorialDetail />
        </Page>
    )
}

export default WebsiteTutorial