import { useState, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import SwipeableViews from 'react-swipeable-views';
import { AppBar, Tabs, Tab, Box } from '@mui/material';

import useWindowSize from '@hooks/useWindowSize';
import { colors } from '@styles/vars';
import YouTube from 'react-youtube';
import { Heading1 } from "./style"
import ImageTutorials from './ImageTutorials';
import VideoTutorials from './VideoTutorials';

const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    {...other}
  >
    {value === index && (
      <Box style={{ padding: '16px 5px' }}>
        {children}
      </Box>
    )}
  </div>
);
function WebsiteTutorialDetail() {
  const { width } = useWindowSize();
  const isMobile = width < 768;
  let [searchParams, setSearchParams] = useSearchParams();
  const [expanded, setExpanded] = useState(searchParams.get('view'));

  const [value, setValue] = useState(0);
  // YOUTUBE TUTORIAL CODE - REACT-YOUTUBE LIBRARY
  const videos = [
    { id: 'n7AN7t1no74', title: 'Overview' },
    { id: '0W54ciRImgE', title: 'Leads dashboard' },
    { id: 'xZrBRMkjjlE', title: 'Clinic dashboard' },
    { id: '9MdQKHniIXs', title: 'Lead list page' },
    { id: 'nHGKmjmDD30', title: 'Edit lead' },
    { id: 'yiSEsrTxR98', title: 'Filter on lead list page' },
    { id: 'sUIV1hxJQU4', title: 'Add user on clinic' },
];

  const players = useRef([]);

  const onPlay = (event) => {
    players.current.forEach((player) => {
      if (player !== event.target) {
        player.pauseVideo();
      }
    });
  };

  const onReady = (event, index) => {
    players.current[index] = event.target;
  };

  const videoOptions = {
    height : isMobile ? '200px' : '310px',
    width :  isMobile ? '100%':'560px',
    playerVars: {
      autoplay: 0,
    },
  };
  // YOUTUBE TUTORIAL CODE - END
  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    // SCROLL TO A PARTICULAR DIV WHEN COMPONENT MOUNTS
    if (expanded) {
      const ele = document.getElementById(expanded);
      if (ele) {
        ele.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  return (
    <div style={{ marginTop: width <= 1279 ? null : '30px', padding: isMobile ? '27px 12px' : '27px 20px', background: colors.white }}>
      <Heading1 isMobile={isMobile}>Purpose</Heading1>
      <p style={{ margin: '5px 0px 15px 0px', fontSize: isMobile ? '13px' : '14px' }}>
        This document is intended to guide you through the efficient use of our ForeverOneApp system. With this software, you can effectively manage all your leads, including checking and managing the number of missed calls, attended calls, booked appointments, and canceled appointments.
      </p>
      {/* IFRAME YOUTUBE OVERVIEW VIDEO */}
      {/* <IFrameDemoVideo isMobile={isMobile} src="https://www.youtube.com/embed/n7AN7t1no74?si=3M7H-9nK4DpZESbJ"
        title="YouTube video player" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
        allowfullscreen /> */}
      <YouTube
        videoId={videos[0].id}
        opts={videoOptions}
        onPlay={onPlay}
        onReady={(event) => onReady(event, 0)}
      />

      <AppBar position="static">
        <Tabs value={value} onChange={handleChangeTabs} aria-label="swipeable tabs"
          sx={{
            background: colors.white,
            '& .MuiTabs-indicator': {
              width: '85px',
              left: '23px'
            }
          }}
        >
          <Tab label="Tutorials" style={{ textTransform: 'none' }} />
          <Tab label="Video Tutorials" style={{ textTransform: 'none' }} />
        </Tabs>
      </AppBar>

      <SwipeableViews index={value} onChangeIndex={handleChangeIndex}>
        <TabPanel value={value} index={0}>
          <ImageTutorials isMobile={isMobile} handleChange={handleChange} expanded={expanded} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <VideoTutorials isMobile={isMobile} videoOptions={videoOptions} onPlay={onPlay} onReady={onReady} videos={videos} />
        </TabPanel>
      </SwipeableViews>



    </div>
  )
}

export default WebsiteTutorialDetail