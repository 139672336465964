import { IconButton, InputAdornment, TextField } from '@mui/material';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import { colors } from '@styles/vars';

const SearchBar = (props) => {
  const searchChangeHandler = (e) => {
    if(e.target.value === ''){
      props.SetSearch(true);
    }
    props.setSearchText(e.target.value);
  };
  return (
    <FormControl fullWidth style={{ paddingLeft: props.paddingLeft ? props.paddingLeft : '8px', margin: props.margin ? props.margin : "8px" }}>
      <TextField
        size="small"
        variant="outlined"
        onChange={searchChangeHandler}
        value={props.SearchText}
        placeholder="Search"
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            props.SetSearch(true);
          }
        }}
        InputProps={{
          // (props.isMobile ? { style: { height: '25px', fontSize: '12px',paddingRight:'4px' } } : null}),
          ...(props.isMobile ?
            {
              style: {
                height: '30px', fontSize: '14px', paddingRight: '4px',borderRadius:'24px'
              }
            }
            :
            {style:{
              borderRadius:'24px'
            }}
          ),
          endAdornment: (
            <InputAdornment position='end' style={{ cursor: 'pointer' }}>
              <IconButton onClick={() => props.SetSearch(true)} style={{padding: props.isMobile ? '3px' : '5px',marginRight: props.isMobile ? '-2px' : '-11px',background:colors.skyBlue,color:colors.blue}}>
                <SearchIcon style={{ ...(props.isMobile ? { fontSize: '1.2rem' } : null) }} />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    </FormControl>
  );
};

export default SearchBar;
