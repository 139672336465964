import { Grid } from '@mui/material';
import Navbar from '@layout/Navbar/index.jsx';
import Form from "./Form/index.jsx";
import { ContactUsHeadingPublic, FormContainerPublic, HeaderContainer } from './Form/style';
import BreadCrumb from '@widgets/BreadCrumbs/index.jsx';
function ContactUsDetailPublic({ isMobile, user, isLoading, setIsLoading }) {
    return (
        <>
            <Navbar />
            <Grid container mt={1} sx={{ display: 'flex', justifyContent: 'center', height: '100% !important' }}>
                <Grid item lg={6} md={8} xs={12} sm={10} style={{ marginTop: '1%', margin: !user ? '0px 10px' : null }}>
                    <HeaderContainer>

                    <BreadCrumb
                        isMobile={true}
                        match={[
                            { pathname: '/home', title: 'Home' },
                            { pathname: `/privacy-policy`, title: 'Privacy Policy' }
                        ]}
                    />
                    <ContactUsHeadingPublic isMobile={isMobile}>Contact Us</ContactUsHeadingPublic>
                    </HeaderContainer>
                    
                    {/* <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', paddingBottom: '8%' }} > */}
                    <FormContainerPublic isMobile={isMobile}>
                        <Form isMobile={isMobile} setIsLoading={setIsLoading} isLoading={isLoading} />
                    </FormContainerPublic>
                    {/* </div> */}
                </Grid>
            </Grid>
        </>
    )
}

export default ContactUsDetailPublic