import styled from 'styled-components/macro';
import theme from 'styled-theming';
import {
  colors,
  dark,
  breakpoints,
  effects,
  flex,
  fonts,
  light,
  textSizes
} from '@styles/vars';

export const Search = styled.form`
  position: relative;
  flex-grow: 1;

  ${breakpoints.tablet} {
    margin-left: 26px;
    max-width: 540px;
  }

  ${breakpoints.laptopL} {
    margin-left: 0;
    width: 100%;
  }
`;

export const Label = styled.label`
  color: ${colors.gray};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  font-size: 14px;
`;

export const Input = styled.input`
  width: 100%;
  border-radius: 8px;
  padding: 10px 10px 10px 50px;
  font-size: ${textSizes['14']};
  background-color: ${theme('theme', {
    light: light.highlight,
    dark: dark.widgetBg
  })};
  transition: box-shadow var(--transition);

  &:hover {
    box-shadow: ${theme('theme', {
      light: `0 0 0 2px #A2C0D4`,
      dark: `0 0 0 2px ${colors.dark}`
    })};
  }

  &:focus {
    box-shadow: 0 0 0 2px ${colors.blue};
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  flex-grow: 1;
`;

export const UserWrapper = styled.div`
  align-items: center;
  justify-content: flex-end;
  gap: 5px;

  .info {
    ${flex.col}
    margin: 0 20px 0 0;
    position: relative;

    .position {
      font-family: ${fonts.accent};
      font-size: ${textSizes['10']};
      text-transform: uppercase;
    }

    .userInfo {
      flex-direction: column;
    }
  }

  .trigger {
    display: flex;
    align-items: center;
    color: ${colors.black};

    .userLogo {
      margin-right: 5px;
      border: 1px solid #2a61ff;
      border-radius: 50%;
      font-size: 19px;
      background-color: #5d87ff;
      color: #ffffff;
      vertical-align: middle;
      width: 2.2rem;
      height: 2.2rem;
      text-align: center;
      line-height: 2.2rem;
      font-weight: 600;
    }
  }

  ${breakpoints.laptopL} {
    margin-right: 20px;
  }

  ${breakpoints.desktop} {
    margin-right: 0;
  }
`;

export const Menu = styled.div`
  z-index: 10000;
  position: absolute;
  right: 85px;
  margin-top: 48px !important;
  margin-right: -100px !important;
  padding: 20px;
  gap: 16px;
  border-radius: 8px;
  font-size: ${textSizes['14']};
  ${flex.col}
  box-shadow: ${effects.widgetShadow};
  transition: var(--transition);
  opacity: 0;
  visibility: hidden;
  min-width: 160px;
  width: max-content;
  background-color: ${theme('theme', {
    light: light.widgetBg,
    dark: dark.widgetBg
  })};

  &.visible {
    opacity: 1;
    visibility: visible;
  }

  button {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;

    i {
      transition: color var(--transition);
      font-size: ${textSizes['16']};
    }

    &:hover,
    &:focus {
      i {
        color: ${theme('theme', {
          light: colors.blue,
          dark: '#fff'
        })};
      }
    }
  }
`;

export const Header = styled.header`
  padding-left: ${(props)=> props.paddingleft ? props.paddingleft : null};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: ${theme('theme', {
    light: colors.aliceblue,
    dark: dark.bodyBg
  })};
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
  transition: top 0.5s ease-in-out;
  gap: 16px;

  &.hidden {
    top: -200px;
  }

  &.sticky {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    top: 0;
  }

  button.square {
    background-color: ${theme('theme', {
      light: light.widgetBg,
      dark: dark.widgetBg
    })};

    &:hover,
    &:focus {
      background-color: ${colors.blue};
    }
  }

  ${breakpoints.laptopL} {
    padding-top: 40px;
    position: static;
    box-shadow: none !important;
  }

  padding : ${(props)=> props.ismobile ? '10px 12px 0px' : props.laptopL && props.paddingleft ? '10px 30px 0px 40px' : props.laptopL ? '10px 30px 0px 30px' : null};

  ${breakpoints.desktop} {
    .logo-wrapper {
      width: 240px;
    }

    ${Search} {
      margin-left: 0;
    }
  }
`;

export const ActionWrapper = styled.div`
  align-items: center;
  justify-content: flex-end;
  gap: 5px;

  .info {
    ${flex.col}
    margin: -30px 20px 30px 0;
    position: relative;

    .position {
      font-family: ${fonts.accent};
      font-size: ${textSizes['10']};
      text-transform: uppercase;
    }

    .userInfo {
      flex-direction: column;
    }
  }

  .trigger {
    display: flex;
    align-items: center;
    color: ${colors.black};

    .userLogo {
      margin-right: 5px;
      border: 1px solid #2a61ff;
      border-radius: 50%;
      font-size: 19px;
      background-color: #5d87ff;
      color: #ffffff;
      vertical-align: middle;
      width: 2.2rem;
      height: 2.2rem;
      text-align: center;
      line-height: 2.2rem;
      font-weight: 600;
    }
  }

  ${breakpoints.laptopL} {
    margin-right: 20px;
  }

  ${breakpoints.desktop} {
    margin-right: 0;
  }
`;

export const LinksWrapper = styled.p`
  padding-bottom: 1px;
  border-color: ${colors.purple};
  font-size: ${(props)=>props.isMobile? '13px' : '14px'};
  font-weight: 500;
  font-family: 'Rubik';
  margin-right: 20px;
  color: ${colors.blue};
  text-wrap: nowrap;
`;
