import mobileChiroImage from '@assets/images/chiro-mobile.png';
import { breakpoints, flex } from '@styles/vars';
import styled from 'styled-components';

const Container = styled.div`
  display: none;
  ${breakpoints.laptop} {
    ${flex.center}
    display: flex;
    width: 35%;
  }
`;

const Image = styled.img`
  width: auto;
  height: 550px;
`;

export default function ImageContainer() {
  return (
    <Container>
      <Image src={mobileChiroImage} alt="Mobile Chiro Image" />
    </Container>
  );
}
