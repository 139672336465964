import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';

import { AuthContext } from '@contexts/authContext';
import { useContext } from 'react';
import { logoutApi } from '@services/auth';

import PersonIcon from '@mui/icons-material/Person';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import AddCommentIcon from '@mui/icons-material/AddComment';
import LockIcon from '@mui/icons-material/Lock';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import PolicyIcon from '@mui/icons-material/Policy';
import LogoutIcon from '@mui/icons-material/Logout';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';

import { useNavigate } from 'react-router';

export default function CurrentUser({isMobile,...props}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const { setIsAuthenticated } = useContext(AuthContext);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleLogoutClick = async () => {
        try {
          let res = await logoutApi();
          if (res.isOk) {
            setIsAuthenticated(false);
            navigate(`/login`);
          }
        } catch (error) {
          console.error(error);
        }
      };

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="Account settings">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ mr: isMobile ? 0 : 1, border: "2px solid #1976d2", borderRadius: "50%", padding: "2px" }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <PersonIcon fontSize='small' color='primary' />
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 8,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem>
                    <div className="userInfo">
                        <div className="h3">
                            {(props.clinic_name ?? props.email ?? '').length > 20
                                ? (props.clinic_name ?? props.email ?? '').substring(0, 15) +
                                '...'
                                : props.clinic_name ?? props.email ?? ''}
                        </div>
                        <small style={{ textTransform: 'uppercase' }}>{props.role}</small>
                    </div>
                </MenuItem>
                <MenuItem>
                    <button
                        onClick={() => {
                            navigate('/profile');
                            handleClose();
                        }}
                        style={{ display: 'flex', gap: '10px', alignItems: 'center' }}
                    >
                        <AccountCircleIcon fontSize="small" /> User Profile
                    </button>
                </MenuItem>
                {/* CLINIC PROFILE IF CLINIC ADMIN */}
                {props.role == 'Admin' && (
                    <MenuItem>
                        <button
                            onClick={() => {
                                navigate('/clinics/edit');
                                handleClose();
                            }}
                            style={{ display: 'flex', gap: '10px', alignItems: 'center' }}
                        >
                            <AddCommentIcon fontSize="small" />
                            Clinic Profile
                        </button>
                    </MenuItem>
                )}
                {/* CHANGE EMAIL */}
                <MenuItem>
                    <button
                        onClick={() => {
                            navigate('/change-email', {
                                state: {
                                    email: props.email
                                }
                            });
                            handleClose();
                        }}
                        style={{ display: 'flex', gap: '10px', alignItems: 'center' }}
                    >
                        <AlternateEmailIcon fontSize="small" />
                        Change Email
                    </button>
                </MenuItem>
                {/* CHANGE PASSWORD */}
                <MenuItem>
                    <button
                        onClick={() => {
                            navigate('/change-password/');
                            handleClose();
                        }}
                        style={{ display: 'flex', gap: '10px', alignItems: 'center' }}
                    >
                        <LockIcon fontSize="small" /> Change Password
                    </button>
                </MenuItem>
                {/* ADD PHRASES FOR SUPER ADMIN */}
                {props.role == 'Super Admin' && (
                    <MenuItem>
                        <button
                            onClick={() => {
                                navigate('/add-phrases');
                                handleClose();
                            }}
                            style={{ display: 'flex', gap: '10px' }}
                        >
                            <AddCommentIcon fontSize="small" />
                            Add Phrases
                        </button>
                    </MenuItem>
                )}
                {/* INTEGRATION FOR CLINIC ADMIN */}
                {/* {props.role == 'Admin' && (
                    <MenuItem>
                        <button
                            onClick={() => {
                                navigate('/integration/edit');
                                handleClose();
                            }}
                            style={{ display: 'flex', gap: '10px', alignItems: 'center' }}
                        >
                            <SettingsRoundedIcon fontSize="small" /> Integration
                        </button>
                    </MenuItem>
                )} */}
                {/* INTEGRATIO FOR SUPER ADMIN */}
                {props.role == 'Super Admin' && (
                    <MenuItem>
                        <button
                            onClick={() => {
                                navigate('/integration');
                                handleClose();
                            }}
                            style={{ display: 'flex', gap: '10px' }}
                        >
                            <SettingsRoundedIcon fontSize="small" /> Integration
                        </button>
                    </MenuItem>
                )}
                {/* CONTACT US */}
                <MenuItem>
                    <button
                        onClick={() => {
                            navigate('/contact-us');
                            handleClose();
                        }}
                        style={{ display: 'flex', gap: '10px', alignItems: 'center' }}
                    >
                        <PermPhoneMsgIcon fontSize="small" /> Contact Us
                    </button>
                </MenuItem>
                {/* PRIVACY POLICY */}
                <MenuItem>
                    <button
                        onClick={() => {
                            navigate('/privacy-policy');
                            handleClose();
                        }}
                        style={{ display: 'flex', gap: '10px', alignItems: 'center' }}
                    >
                        <PolicyIcon fontSize="small" /> Privacy Policy
                    </button>
                </MenuItem>
                {/* LOGOUT */}
                <MenuItem>
                    <button
                        onClick={handleLogoutClick}
                        style={{ display: 'flex', gap: '10px',alignItems: 'center' }}
                    >
                        <LogoutIcon fontSize="small" /> Logout
                    </button>
                </MenuItem>


            </Menu>
        </React.Fragment>
    );
}